<template>
  <card title="Rating">
    <div v-for="rating in review.ratings" class="mb-15"
         :key="rating.name">
      <base-input :label="rating.name"
                  v-model="rating.value"
                  :placeholder="rating.name">
      </base-input>
      <div v-if="rating.answers && rating.answers.length" class="mb-15">
        Answer: {{ rating.answers[0] }}
      </div>
      <div v-if="rating.client_comment && rating.client_comment.length" class="mb-30">
        <div class="mb-15">Client comment:</div>
        <el-input
          v-model="rating.client_comment"
          :autosize="{ minRows: 4  }"
          type="textarea"
          placeholder="text"
        />
      </div>
    </div>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    defaultRatings: [
      {
        'name' : 'main',
        'value' : 0
      },
      {
        'name' : 'comfort',
        'value' : 0
      },
      {
        'name' : 'doctor',
        'value' : 0
      },
      {
        'name' : 'manager',
        'value' : 0
      },
      {
        'name' : 'personal',
        'value' : 0
      },
      {
        'name' : 'translation',
        'value' : 0
      },
      {
        'name' : 'relative_price',
        'value' : 0
      }
    ]
  }),
  computed: {
    ...mapState('reviews', ['review']),
  },
  created() {
    let tmpArray = [];

    this.review.ratings = [...this.review.ratings, ...this.defaultRatings]
      .filter(item => {
        if (tmpArray.indexOf(item.name) === -1) {
          tmpArray.push(item.name);
          return true
        }
        return false;
      });
  }
}
</script>
