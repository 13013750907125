<template>
  <div class="row">
    <div class="col-12">
      <card title="Unlink requests">
        <table class="table table-responsive" v-if="unlinkEntities && unlinkEntities.length">
          <tr>
            <th>Clinic ID</th>
            <th>Clinic name</th>
            <th>Entity type</th>
            <th>Entity name</th>
            <th>Created at</th>
            <th>Status</th>
            <th>Apply</th>
            <th>Reject</th>
          </tr>
          <tr v-for="entities in unlinkEntities">
            <td>{{entities.clinic_id}}</td>
            <td>{{entities.clinic_name}}</td>
            <td>{{entities.entity_type}}</td>
            <td>{{entities.entities.length ? entities.entities.map((el) => el.name).join(', ') : ''}}</td>
            <td>{{entities.created_at.substr(0, 10) }}</td>
            <td>{{entities.status}}</td>
            <td><button class="btn btn-primary"
                        @click="changeUnlinkStatusEvent(entities.clinic_id, entities.entity_type, 'apply')"
                        v-if="entities.status === 'new'">Apply</button></td>
            <td><button class="btn btn-danger"
                        @click="changeUnlinkStatusEvent(entities.clinic_id, entities.entity_type, 'reject')"
                        v-if="entities.status === 'new'">Reject</button></td>
          </tr>
        </table>
        <div class="card-body" v-else>
          No data
        </div>
      </card>
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapState('cmsApiCatalogs', ['unlinkEntities']),
    },
    created() {
      this.getUnlinkEntities();
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getUnlinkEntities', 'changeUnlinkStatus']),
      changeUnlinkStatusEvent(clinicId, entityType, status) {
        this.$store.dispatch('setLoader', true);
        return this.changeUnlinkStatus({ clinicId, entityType, status }).then((response) => {
          console.log(response);
          this.$notify({ type: 'success', title: 'Saved' })
          this.getUnlinkEntities();
        }).catch(() => this.$notify({ type: 'warning', title: 'Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      },
    },
  };
</script>
