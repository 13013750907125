<template>
  <el-tabs v-model="activeName">
    <el-tab-pane label="Main info" name="commonInfo">
      <div class="row">
        <div class="col-md-6">
          <template v-if="isConnectionVisible">
            <IllnessConnection
              v-if="entityData.type === 'illness'"
              :entity="entityData.entity"/>
            <template v-else>
              <ProcedureConnections v-if="entityData.entity.id"
                                    :entity="entityData.entity"
                                    :entityType="entityData.type"
                                    :entitiesString="entityData.entitiesString"
                                    @get-procedure-event="getEntityEvent"/>
            </template>
          </template>
          <EntityNameInfo v-if="domains && domains.length" :entity="entityData.entity"/>
          <EntityFlags :entityData="entityData"/>
        </div>
        <div class="col-md-6">
          <EntityDetails v-if="entityData.details" :details="entityData.details"/>
          <CityCountry :entity="entityData.entity"
                       v-if="entityData.type === 'city'"/>
        </div>
      </div>
    </el-tab-pane>
    <template v-if="domains && domains.length">
      <el-tab-pane label="Name forms" name="nameForms">
        <EntityNameForms :entityData="entityData"/>
      </el-tab-pane>
      <template v-if="!isNewEntity">
        <el-tab-pane label="Texts" name="texts">
          <EntityTexts :data="entityData.texts"/>
        </el-tab-pane>
        <el-tab-pane label="Countries texts" name="connectionWithCountries"
                     v-if="entityData.type !== 'city' && entityData.type !== 'country'">
          <ConnectionWithCountries :entitiesString="entityData.entitiesString"/>
        </el-tab-pane>
      </template>
    </template>
    <div class="row">
      <div class="col-12">
        <base-button type="primary"
                     v-if="activeName !== 'texts' && activeName !== 'connectionWithCountries'"
                     @click="save()">{{isNewEntity ? 'Create' : 'Update'}} {{ entityData.type }}</base-button>
      </div>
    </div>
  </el-tabs>
</template>

<script>
import ProcedureConnections from '@/components/Entities/ProcedureConnections';
import IllnessConnection from '@/components/Entities/IllnessConnection';
import EntityNameInfo from '@/components/Entities/EntityNameInfo';
import EntityFlags from '@/components/Entities/EntityFlags';
import EntityDetails from '@/components/Entities/EntityDetails';
import CityCountry from '@/components/Entities/CityCountry';
import EntityNameForms from '@/components/Entities/EntityNameForms';
import EntityTexts from '@/components/Entities/EntityTexts';
import ConnectionWithCountries from '@/components/Entities/ConnectionWithCountries';
import { mapState, mapActions } from 'vuex';

export default {
  props: ['entityData'],
  components: {
    EntityNameInfo,
    EntityDetails,
    EntityFlags,
    ProcedureConnections,
    IllnessConnection,
    EntityNameForms,
    EntityTexts,
    ConnectionWithCountries,
    CityCountry,
  },
  data: () => ({
    activeName: 'commonInfo',
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['domains']),
    isConnectionVisible() {
      return this.entityData.type !== 'illness-kind' && this.entityData.type !== 'city' && this.entityData.type !== 'country';
    },
    isNewEntity() {
      return this.entityData.entityId === 'new';
    },
  },
  created() {
    this.getIllnessKinds({ perPage: 1500 });
    this.getEntityEvent();
    this.getDomains();
  },
  methods: {
    ...mapActions('cmsApiCatalogs', ['getIllnessKinds', 'getEntity', 'updateEntityDetails', 'getDomains', 'saveEntity']),
    getEntityEvent() {
      if (!this.isNewEntity) {
        this.$store.dispatch('setLoader', true);
        return this.getEntity({
          entityId: this.entityData.entityId,
          type: this.entityData.type,
          entitiesString: this.entityData.entitiesString,
        })
          .finally(() => this.$store.dispatch('setLoader', false));
      }
      return null;
    },
    async save() {
      if (this.entityData.type === 'country') {
        const entity = this.entityData.entity;
        if (!(/^[a-zA-Z'-\s]+$/.test(entity.code)) || entity.code.length < 1) {
          alert('Iso code has to be min 2 symbols and latin');
          return;
        } else if (!entity.country_group) {
          alert('Select country group');
          return;
        }
      }
      if (this.entityData.type === 'illness' && !this.entityData.entity.illness_kind) {
        alert('Set specialty');
        return;
      }
      if (this.entityData.type === 'city' && !this.entityData.entity.country) {
        alert('Set country');
        return;
      }
      this.$store.dispatch('setLoader', true);

      try {
        const entityResponse = await this.saveEntity({ data: this.entityData.entity, entitiesString: this.entityData.entitiesString });

        if (this.entityData.details) {
          if (entityResponse && entityResponse.data && entityResponse.data.id) {
            this.entityData.details = this.entityData.details.map(detail => {
              const detailObj = { ...detail };

              if (this.entityData.type === 'country') {
                detailObj.iso_code = this.entityData.entity.code;
              } else {
                detailObj[`${this.entityData.type.replace('-', '_')}_id`] = entityResponse.data.id;
              }
              return detailObj;
            })
          }

          await this.updateEntityDetails({
            data: this.entityData.details,
            entityId: this.entityData.type === 'country' ? this.entityData.entity.code : entityResponse.data.id,
            entitiesString: this.entityData.entitiesString,
          });
        }

        if (this.isNewEntity) {
          this.$router.push({ params: { entityId: entityResponse.data.id }});
        }

        this.$notify({ type: 'success', title: 'Saved' });
      } catch (e) {
        this.$notify({ type: 'warning', title: 'Save Failed' });
      } finally {
        this.$store.dispatch('setLoader', false);
      }
    },
  }
}
</script>
