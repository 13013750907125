<template>
  <div class="clinic-video">
    <ClinicVideosList v-if="!isItemVisible"
                      @openMediaItem="openMediaItem"/>
    <ClinicVideosItem @closeMediaItem="isItemVisible = false"
                      :isNewVideo="isNewVideo" v-else/>
  </div>
</template>

<script>
import ClinicVideosList from '@/components/Clinics/video/ClinicVideosList';
import ClinicVideosItem from '@/components/Clinics/video/ClinicVideosItem';

export default {
  components: { ClinicVideosList, ClinicVideosItem },
  data: () => ({
    isItemVisible: false,
    isNewVideo: false,
  }),
  methods: {
    openMediaItem(isNewVideo) {
      this.isNewVideo = isNewVideo;
      this.isItemVisible = true;
    },
  },
};
</script>
