<template>
  <card title="Specialties">
    <el-select v-model="entity.illness_kind"
               placeholder="Specialties"
               no-data-text="No data"
               no-match-text="No match"
               @change="entity.illnessKind = entity.illness_kind"
               filterable
               reserve-keyword
               value-key="id">
      <el-option v-for="item in illnessKinds"
                 :key="item.id"
                 :label="item.name.en"
                 :value="item">
      </el-option>
    </el-select>
  </card>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    props: ['entity'],
    computed: {
      ...mapState('cmsApiCatalogs', ['illness-kinds']),
      illnessKinds() {
        return this['illness-kinds'];
      },
    }
  }
</script>
