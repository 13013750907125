<template>
  <card title="Flags">
    <el-checkbox v-for="flag in review.flags"
                 :key="flag.name"
                 v-model="flag.value"
    >
      {{ flag.name }}
    </el-checkbox>
  </card>
</template>

<script>
  import { mapState } from "vuex";

  export default {
    data: () => ({
      defaultFlags: [
        {
          'name' : 'is_approved',
          'value' : false
        },
        {
          'name' : 'is_anonymously',
          'value' : false
        },
        {
          'name' : 'is_notification_sent',
          'value' : false
        },
        {
          'name' : 'would_recommend',
          'value' : false
        },
      ]
    }),
    computed: {
      ...mapState('reviews', ['review']),
    },
    created() {
      let tmpArray = [];

      this.review.flags = [...this.review.flags, ...this.defaultFlags]
        .filter(item => {
          if (tmpArray.indexOf(item.name) === -1) {
            tmpArray.push(item.name);
            return true
          }
          return false;
        });
    }
  }
</script>
