<template>
  <div>
    <card title="Filters">
      <div class="row">
        <div class="col-md-3 pr-md-1">
          <base-input :placeholder="entityData.searchPlaceholder"
                      v-model="filters.name">
          </base-input>
        </div>
        <div class="col-md-3 px-md-1" v-if="isIllnessKindsSelectVisible">
          <el-select v-model="filters.illnessKindIds"
                     placeholder="Specialties"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     multiple
                     value-key="id">
            <el-option v-for="item in illnessKinds"
                       :key="item.id"
                       :label="item.name.en"
                       :value="item.id">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-3 px-md-1" v-if="entityData.type === 'city'">
          <el-select v-model="filters.country_iso_codes"
                     placeholder="Countries"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     multiple
                     value-key="id">
            <el-option v-for="country in countries"
                       :key="country.code"
                       :label="country.name.en"
                       :value="country.code">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2 pr-md-1">
          <el-checkbox v-model="filters.hide">Only hide</el-checkbox>
        </div>
        <div class="col-md-2 px-md-1">
          <base-button @click="clearFilters()">Clear filters</base-button>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <base-button @click="createEntity()" type="primary">
            Create {{ entityData.type === 'illness-kind' ? 'specialty' : entityData.type }}
          </base-button>
        </div>
      </div>

    </card>

  </div>
</template>


<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: ['page', 'entityData'],
  data: () => ({
    filters: {
      name: '',
      hide: false,
      illnessKindIds: [],
      country_iso_codes: [],
    },
  }),
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: 'setFilter',
    },
    page() {
      this.setFilter(this.page);
    },
  },
  computed: {
    ...mapState('cmsApiCatalogs', ['illness-kinds', 'countries']),
    illnessKinds() {
      return this['illness-kinds'];
    },
    isIllnessKindsSelectVisible() {
      return this.entityData.type !== 'illness-kind' && this.entityData.type !== 'city' && this.entityData.type !== 'country';
    },
  },
  created() {
    if (this.isIllnessKindsSelectVisible) {
      this.getIllnessKinds({ perPage: 1500 });
    }
    if (this.entityData.type === 'city') {
      this.getCountries({ perPage: 1500 });
    }
  },
  methods: {
    ...mapActions('cmsApiCatalogs', ['getOperations', 'getIllnesses', 'getDiagnostics', 'getIllnessKinds', 'getCities', 'getCountries']),
    createEntity() {
      let routeData = this.$router.resolve({ name: this.entityData.type, params: { entityId: 'new' } });
      window.open(routeData.href, '_blank');
    },
    getEntityEvent(params) {
      this.$store.dispatch('setLoader', true);
      return this[this.entityData.getEntitiesQuery]({ perPage: 50, ...params }).finally(() => this.$store.dispatch('setLoader', false));
    },
    setFilter(currentPage) {
      let page = null;

      if (!isNaN(currentPage)) {
        page = currentPage;
      }

      const purifiedFilters = this.cleanFilters(this.filters);
      const params = { ...purifiedFilters };
      if (page) {
        params.page = page;
      } else {
        this.$emit('reset-page');
      }
      return this.getEntityEvent(params);
    },
    cleanFilters(filters) {
      return Object.keys(filters).reduce((acc, key) => {
        const value = filters[key];
        const isArray = Array.isArray(value);

        if (value || (isArray && value.length >= 1)) {
          acc[key] = value;
        }

        return acc;
      }, {});
    },
    buildFilters() {
      return {
        name: '',
        hide: false,
        illnessKindIds: [],
        country_iso_codes: [],
      };
    },
    clearFilters() {
      this.filters = this.buildFilters();
      this.$emit('reset-page');
    },
  }
}
</script>
