<template>
  <div class="row">
    <div class="col-12">
      <card :title="entityData.tableName">
        <table class="table table-responsive" v-if="entityData.entities && entityData.entities.length">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th v-if="isIllnessKindsTDVisible">Specialties</th>
            <th v-if="entityData.type === 'city'">Country</th>
            <th>Is Hide</th>
            <th v-if="isProcedure">Use for listing</th>
            <th></th>
          </tr>
          <tr v-for="(entity) in entityData.entities" :key="entity.id">
            <td><strong>{{entity.id}}</strong></td>
            <td>{{entity.name.en}}</td>
            <td v-if="isIllnessKindsTDVisible">
              <template v-if="entityData.type === 'illness' && entity.illnessKind">
                {{entity.illnessKind.name.en}}
              </template>
              <template v-else>
                <template v-if="entity.illnessKinds && entity.illnessKinds.length">
                <span v-for="(illnessKind, index) in entity.illnessKinds">
                  {{illnessKind.name.en}}<template v-if="index < entity.illnessKinds.length - 1">,</template>
                </span>
                </template>
              </template>
            </td>
            <td v-if="entityData.type === 'city'">
              {{entity.country && entity.country.name ? entity.country.name.en : ''}}
            </td>
            <td>{{entity.is_hide ? 'Yes' : 'No'}}</td>
            <td v-if="isProcedure">{{entity.use_for_listing ? 'Yes' : 'No'}}</td>
            <td>
              <button class="btn btn-primary btn-link" @click="editItem(entity.id)">
                <i class="tim-icons icon-pencil"></i>
              </button>
            </td>
          </tr>
        </table>
        <div class="card-body" v-else>
          No data
        </div>
      </card>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['entityData'],
    computed: {
      isProcedure() {
        return this.entityData.type === 'operation' || this.entityData.type === 'diagnostic';
      },
      isIllnessKindsTDVisible() {
        return this.entityData.type !== 'illness-kind' && this.entityData.type !== 'city' && this.entityData.type !== 'country';
      },
    },
    methods: {
      editItem(entityId) {
        let routeData = this.$router.resolve({ name: this.entityData.type, params: { entityId } });
        window.open(routeData.href, '_blank');
      },
    }
  }
</script>
