<template>
  <card title="Details">
    <base-input v-for="detail in details"
                :key="detail.detail_name"
                :label="detail.detail_name"
                v-model="detail.detail_value"
                :placeholder="detail.detail_name">
    </base-input>
  </card>
</template>

<script>
export default {
  props: ['details'],
}
</script>
