<template>
  <div class="clinic-parser">
    <card title="Clinic parser">
      <div class="row">
        <div class="col-md-6">
          <base-input label="Domain"
                      v-model="params.domain"
                      placeholder="Domain">
          </base-input>
          <base-input label="Query"
                      v-model="params.query"
                      placeholder="Query">
          </base-input>
          <base-input label="Tokens"
                      type="number"
                      v-model="params.tokens"
                      placeholder="Tokens">
          </base-input>
        </div>
        <div class="col-md-6">
          <label>Prompt</label>
          <el-input
            v-model="params.prompt"
            :autosize="{ minRows: 8 }"
            type="textarea"
            placeholder="Prompt"
          />
        </div>
      </div>
      <div class="row mt-16">
        <div class="col-12">
          <base-button type="primary" @click="parseClinicEvent()">Get answer</base-button>
        </div>
      </div>
    </card>
    <card title="Answer" v-if="parsedClinic && Object.keys(parsedClinic).length">
      <div class="row">
        <div class="col-12">
          <div>
            <label>Site text</label>
            <el-input
              v-model="parsedClinic.site_text"
              :autosize="{ minRows: 5 }"
              type="textarea"
              placeholder="site_text"
            />
          </div>
          <div class="mt-16">
            <label>Ai response</label>
            <el-input
              v-model="parsedClinic.ai_response"
              :autosize="{ minRows: 5 }"
              type="textarea"
              placeholder="ai_response"
            />
          </div>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    data: () => ({
      params: {
        domain: '',
        query: '',
        tokens: 1000,
        prompt: '',
      },
    }),
    computed: {
      ...mapState('ai', ['parsedClinic']),
    },
    methods: {
      ...mapActions('ai', ['parseClinic']),
      parseClinicEvent() {
        this.$store.dispatch('setLoader', true);
        return this.parseClinic({ ...this.params, tokens: +this.params.tokens }).finally(() => this.$store.dispatch('setLoader', false));
      },
    },
  };
</script>
