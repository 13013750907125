<template>
  <div class="clinic-video__list">
    <div class="row">
      <div class="col-12">
        <card title="Video list">
          <table class="table" v-if="clinicsMedia">
            <tr>
              <th>Type</th>
              <th>Description</th>
              <th>Link</th>
              <th>Status</th>
              <th></th>
            </tr>
            <tr v-for="media in clinicsMedia">
              <td>{{media.media_type}}</td>
              <td>{{media.description && media.description.en}}</td>
              <td>{{media.link}}</td>
              <td>{{media.status}}</td>
              <td>
                <button class="btn btn-primary btn-link" @click="editMedia(media.id)">
                  <i class="tim-icons icon-pencil"></i>
                </button>
              </td>
            </tr>
          </table>
          <div class="card-body" v-else>
            No data
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <base-button type="primary" @click="createNew()">Create new</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('cmsApiCatalogs', ['clinicsMedia', 'clinicsMediaItem']),
  },
  mounted() {
    this.getClinicsMedia({
      clinicId: this.$route.params.clinicId,
      params: {
        should_have_tags: [`clinic:${this.$route.params.clinicId}`],
      },
    });
  },
  methods: {
    ...mapMutations('cmsApiCatalogs', ['SET_DATA']),
    ...mapActions('cmsApiCatalogs', ['getClinicsMedia', 'getClinicsMediaItem']),
    editMedia(mediaId) {
      this.$store.dispatch('setLoader', true);
      return this.getClinicsMediaItem(mediaId)
        .then(() => this.clinicsMediaItem ? this.$emit('openMediaItem') : alert('Failed'))
        .catch(() => this.$notify({ type: 'warning', title: 'Failed' }))
        .finally(() => this.$store.dispatch('setLoader', false));
    },
    createNew() {
      this.SET_DATA({
        type: 'clinicsMediaItem',
        data: {
          link: '',
          tags: [],
          lang: 'en',
          description: {},
        }
      });
      this.$emit('openMediaItem', true);
    },
  },
};
</script>
