<template>
  <card v-if="clinic && clinic.name && entityInfo"
        :title="`${clinic.name.en} (Clinic ID: ${clinic.id}). ${entityInfo.name ? entityInfo.name.en : ''} (Entity ID: ${entityId})`">
    <el-tabs v-model="activeLang" @tab-click="getClinicEntityAliasesByLangEvent()">
      <el-tab-pane v-for="domain in domains"
                   :key="domain.language"
                   :label="`Text ${domain.language.toUpperCase()}`"
                   :name="domain.language">
        <card v-for="(textAlias, index) in textAliases"
              :title="textAlias.alias"
              :key="index">
          <base-input :label="textAlias.alias"
                      v-model="textAlias.text"
                      v-if="textAlias.alias === 'video_offer'"
                      :placeholder="textAlias.alias">
          </base-input>
          <ckeditor v-model="textAlias.text" :config="{ allowedContent: true }" v-else></ckeditor>
        </card>
        <base-button type="primary" @click="save()">Update {{ entityInfo.name ? entityInfo.name.en : '' }} ({{domain.language.toUpperCase()}})</base-button>
      </el-tab-pane>
    </el-tabs>
  </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

  export default {
    props: ['clinicId', 'entityId', 'entity'],
    data: () => ({
      activeLang: null,
      textAliases: [],
    }),
    computed: {
      ...mapState('cmsApiCatalogs', ['domains', 'illness-kinds', 'illnesses', 'diagnostics', 'operations']),
      ...mapState('clinics', ['clinic', 'clinicEntityAliasesByLang']),
      entityInfo() {
        if (Array.isArray(this[this.entity])) {
          return this[this.entity].find(el => el.id == this.entityId);
        }
      },
      defaultTextAliases() {
        let aliasesByEntity;

        switch (this.entity) {
          case 'illness-kinds': aliasesByEntity = ['txt_kind', 'txt_doctors']; break;
          case 'illnesses': aliasesByEntity = ['txt_illness', 'video_offer', 'txt_doctors']; break;
          case 'diagnostics': aliasesByEntity = ['txt_diagnostic', 'txt_doctors']; break;
          case 'operations': aliasesByEntity = ['txt_operation', 'video_offer', 'txt_doctors', 'txt_timeline']; break;
        }

        return aliasesByEntity.reduce((acc, key) => {
          let o = { alias: key, text: '' }
          acc.push(o);
          return acc;
        }, []);
      },
      objForData() {
        return {
          clinicId: this.clinicId,
          lang: this.activeLang,
          entity: this.entity === 'illness-kinds' ? 'illnesses_kinds' : this.entity,
          entityId: this.entityId,
        }
      }
    },
    created() {
      this.getClinic(this.clinicId);
      this.getEntityInfo();
      this.getDomainsEvent();
    },
    methods: {
      ...mapActions('clinics', ['getClinic', 'getClinicEntityAliasesByLang', 'updateClinicEntityAliasesByLang']),
      ...mapActions('cmsApiCatalogs', ['getDomains', 'getIllnessKinds', 'getIllnesses', 'getDiagnostics', 'getOperations']),
      save() {
        this.$store.dispatch('setLoader', true);

        return this.updateClinicEntityAliasesByLang({
          ...this.objForData,
          data: {
            texts: this.textAliases,
          },
        }).then(() => this.$notify({ type: 'success', title: 'Saved' }))
          .catch(() => this.$notify({ type: 'warning', title: 'Save Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      },
      async getDomainsEvent() {
        await this.getDomains();
        this.activeLang = this.domains[0].language;
        this.getClinicEntityAliasesByLangEvent();
      },
      getClinicEntityAliasesByLangEvent() {
        this.$store.dispatch('setLoader', true);
        this.textAliases = [];

        return this.getClinicEntityAliasesByLang(this.objForData).then(() => {
          let tmpArray = [];

          this.textAliases = [...this.clinicEntityAliasesByLang, ...this.defaultTextAliases]
            .filter(item => {
              if (tmpArray.indexOf(item.alias) === -1) {
                tmpArray.push(item.alias);
                return true
              }
              return false;
            });
        }).finally(() => this.$store.dispatch('setLoader', false));
      },
      getEntityInfo() {
        switch (this.entity) {
          case 'illness-kinds': this.getIllnessKinds({ perPage: 1500 }); break;
          case 'illnesses': this.getIllnesses({ perPage: 1500 }); break;
          case 'diagnostics': this.getDiagnostics({ perPage: 1500 }); break;
          case 'operations': this.getOperations({ perPage: 1500 }); break;
        }
      },
    }
  };
</script>
