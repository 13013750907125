<template>
  <div class="review__image-wrap">
    <div class="review__image" v-for="(image, index) in images" :key="index">
      <img :src="getUrl(image.base_url)" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    props: ['images'],
    methods: {
      getUrl(baseUrl) {
        return `https://img.bookimed.com/review/${baseUrl.slice(baseUrl.lastIndexOf('/') + 1)}_t.jpeg`;
      },
    }
  }
</script>

<style lang="scss">
  .review{
    &__image{
      width: calc(25% - 12px);
      img{
        width: 100%;
      }
      &-wrap{
        border: 1px solid #ccc;
        padding: 16px;
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }
    }
  }
</style>
