<template>
  <Entities :entityData="entityData"/>
</template>

<script>
import Entities from '@/components/Entities/Entities';
import { mapState } from 'vuex';

export default {
  components: { Entities },
  computed: {
    ...mapState('cmsApiCatalogs', ['illnessesMeta', 'illnesses']),
    entityData() {
      return {
        type: 'illness',
        meta: this.illnessesMeta,
        searchPlaceholder: "Illness's name",
        getEntitiesQuery: 'getIllnesses',
        entities: this.illnesses,
        tableName: 'Illnesses',
      };
    },
  },
};
</script>
