<template>
  <div>
    <ClinicFilters :page="page" @reset-clinics-page="page = 1"/>
    <ClinicsTable/>
    <el-pagination
      v-if="pageCount > 1"
      background
      prev-text="Previous"
      next-text="Next"
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      :current-page.sync="page"
      class="text-center mt-20"
      :page-count="pageCount">
    </el-pagination>
  </div>
</template>
<script>
import ClinicFilters from '@/components/Clinics/ClinicFilters';
import ClinicsTable from '@/components/Clinics/ClinicsTable';
import { mapState } from 'vuex';

export default {
  components: {
    ClinicFilters,
    ClinicsTable,
  },
  data: () => ({
    page: 1,
    pageSize: 20,
  }),
  computed: {
    ...mapState('clinics', ['clinicsMeta']),
    pageCount() {
      return this.clinicsMeta['total_page'];
    }
  },
};
</script>
