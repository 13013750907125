<template>
  <Entity :entityData="entityData"/>
</template>

<script>
import Entity from '@/components/Entities/Entity';
import { mapState } from 'vuex';

export default {
  props: ['entityId'],
  components: { Entity },
  computed: {
    ...mapState('cmsApiCatalogs', ['city']),
    entityData() {
      return {
        type: 'city',
        entitiesString: 'cities',
        entity: this.city.city,
        entityId: this.entityId,
        texts: {
          getMethod: 'getEntityTexts',
          saveMethod: 'saveEntityTexts',
          entitiesString: 'cities',
          defaultTexts: [
            { text: '', alias: 'txt_short'},
            { text: '', alias: 'txt_after_3_clinics'},
          ],
        },
      };
    },
  },
}
</script>
