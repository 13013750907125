<template>
  <card title="Clinic's accreditations">
    <el-select v-model="clinic.accreditation_ids"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               reserve-keyword
               multiple
               value-key="id">
      <el-option v-for="accreditation in accreditations"
                 :key="accreditation.id"
                 :label="accreditation.name.en"
                 :value="accreditation.id">
      </el-option>
    </el-select>
  </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('clinics', ['clinic', 'accreditations']),
    },
    created() {
      this.getAccreditations();
    },
    methods: {
      ...mapActions('clinics', ['getAccreditations']),
    },
  }
</script>
