<template>
  <el-tabs v-model="activeName">
    <el-tab-pane v-for="(forms, lang) in entityData.entity.name_forms"
                 :key="lang"
                 :label="`Text ${lang.toUpperCase()}`"
                 :name="`Text ${lang.toUpperCase()}`">
      <card v-for="(formValue, key) in forms"
            :title="key"
            :key="key">
        <base-input>
            <textarea rows="4" cols="80"
                      class="form-control"
                      :placeholder="key"
                      v-model="entityData.entity.name_forms[lang][key]"
            ></textarea>
        </base-input>
      </card>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['entityData'],
  data: () => ({
    activeName: null,
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['domains']),
    defaultEntityNameForms() {
      let arr = [];
      let formAliasLength = 15;

      for(let i = 0; i < formAliasLength; i++) {
        arr[i] = { alias: `form_${i+1}` };
      }

      return [{ alias: 'base_form' }, ...arr];
    }
  },
  created() {
    this.activeName = `Text ${this.domains[0].language.toUpperCase()}`;
    this.initEntityNameForms();
  },
  watch: {
    entityData() {
      this.initEntityNameForms();
    },
  },
  methods: {
    initEntityNameForms() {
      let nameForms = {};

      this.domains.forEach(domain => {
        let language = {};
        let nameFormsByLanguage = this.entityData.entity.name_forms[domain.language];

        this.defaultEntityNameForms.forEach(defaultNameForm => {
          if (nameFormsByLanguage && nameFormsByLanguage.hasOwnProperty(defaultNameForm.alias)) {
            language[defaultNameForm.alias] = nameFormsByLanguage[defaultNameForm.alias];
          } else {
            language[defaultNameForm.alias] = '';
          }

          nameForms[domain.language] = language;
        });
      });

      this.entityData.entity.name_forms = nameForms;
    },
  }
}
</script>
