<template>
  <card title="Rating">
    <base-input label="Rating" v-model="clinic.rating.rating" placeholder="Rating" type="number"></base-input>
    <base-input label="Facebook" v-model="clinic.rating.facebook" placeholder="Facebook" type="number"></base-input>
    <base-input label="Google" v-model="clinic.rating.google" placeholder="Google" type="number"></base-input>
    <base-input label="Percent of satisfied clients" v-model="clinic.rating.percent_of_satisfied_clients" placeholder="Percent of satisfied clients" type="number"></base-input>
  </card>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('clinics', ['clinic']),
    },
  }
</script>
