<template>
  <Entities :entityData="entityData"/>
</template>

<script>
import Entities from '@/components/Entities/Entities';
import { mapState } from 'vuex';

export default {
  components: { Entities },
  computed: {
    ...mapState('cmsApiCatalogs', ['operationsMeta', 'operations']),
    entityData() {
      return {
        type: 'operation',
        meta: this.operationsMeta,
        searchPlaceholder: "Operation's name",
        getEntitiesQuery: 'getOperations',
        entities: this.operations,
        tableName: 'Operations',
      };
    },
  },
};
</script>
