<template>
  <card title="Statistics">
    <base-input label="Children per year" v-model="clinic.statistics.children_per_year" placeholder="Children per year" type="number"></base-input>
    <base-input label="Doctors number" v-model="clinic.statistics.doctors_number" placeholder="Doctors number" type="number"></base-input>
    <base-input label="Employees number" v-model="clinic.statistics.employees_number" placeholder="Employees number" type="number"></base-input>
    <base-input label="Number of beds" v-model="clinic.statistics.number_of_beds" placeholder="Number of beds" type="number"></base-input>
    <base-input label="Number of buildings" v-model="clinic.statistics.number_of_buildings" placeholder="Number of buildings" type="number"></base-input>
    <base-input label="Number of departments" v-model="clinic.statistics.number_of_departments" placeholder="Number of departments" type="number"></base-input>
    <base-input label="Number of reviews" v-model="clinic.statistics.number_of_reviews" placeholder="Number of reviews" type="number"></base-input>
    <base-input label="Operations per year" v-model="clinic.statistics.operations_per_year" placeholder="Operations per year" type="number"></base-input>
    <base-input label="Patient per year" v-model="clinic.statistics.patient_per_year" placeholder="Patient per year" type="number"></base-input>
    <base-input label="Year foundation" v-model="clinic.statistics.year_foundation" placeholder="Year foundation" type="number"></base-input>
  </card>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('clinics', ['clinic']),
    },
  }
</script>
