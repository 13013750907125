<template>
  <div>
    <card title="Main specialties connection">
      <div class="single-page__specialties">
        <button @click="openDialog('Specialties')"><i class="fa fa-heartbeat"></i> Specialties</button>
        <button @click="openDialog('Illnesses')"><i class="fa fa-disease"></i> Illnesses</button>
      </div>
    </card>
    <el-dialog :visible.sync="isDialogOpen"
               :close-on-click-modal="false"
    >
      <template v-if="dialogType === 'Specialties'">
        <h3>Specialties</h3>
        <el-select v-model="entity.illness_kinds"
                   placeholder="Specialties"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in illnessKinds"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item">
          </el-option>
        </el-select>
      </template>
      <template v-if="dialogType === 'Illnesses'">
        <h3>Illnesses</h3>
        <el-select v-model="illnessesByProcedure.illnesses"
                   placeholder="Illnesses"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in illnesses"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item">
          </el-option>
        </el-select>
      </template>
      <div class="el-dialog__btns">
        <button type="button" class="btn btn-primary" @click="update()">Save</button>
        <button type="button" class="btn btn-default" @click="isDialogOpen = false">Close</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import _ from 'lodash';

export default {
  props: ['entity', 'entityType', 'entitiesString'],
  data: () => ({
    isDialogOpen: false,
    dialogType: null,
    procedureIllnessKindIdsInit: [],
    procedureIllnessesIdsInit: [],
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['illness-kinds', 'illnesses', 'illnessesByProcedure']),
    illnessKinds() {
      return this['illness-kinds'];
    },
    procedureIllnessKindIds() {
      return Array.isArray(this.entity.illness_kinds) ? this.entity.illness_kinds.map((item) => item.id) : [];
    },
    procedureIllnessesIds() {
      return Array.isArray(this.illnessesByProcedure.illnesses) ? this.illnessesByProcedure.illnesses.map((item) => item.id) : [];
    },
    entityData() {
      let entityIdsName, entity, ids, idsInit;

      switch (this.dialogType) {
        case 'Specialties':
          entityIdsName = 'illness_kind_ids';
          entity = 'illness-kinds';
          ids = 'procedureIllnessKindIds';
          idsInit = 'procedureIllnessKindIdsInit';
          break;
        case 'Illnesses':
          entityIdsName = 'illness_ids';
          entity = 'illnesses';
          ids = 'procedureIllnessesIds';
          idsInit = 'procedureIllnessesIdsInit';
          break;
      }

      return { entityIdsName, entity, ids, idsInit };
    }
  },
  created() {
    this.getIllnesses({ perPage: 1500, illnessKindIds: this.procedureIllnessKindIds });
    this.getIllnessesByProcedureEvent();
    this.procedureIllnessKindIdsInit = JSON.parse(JSON.stringify(this.procedureIllnessKindIds));
  },
  methods: {
    ...mapActions('cmsApiCatalogs', ['linkOrUnlinkConnection', 'getIllnesses', 'getIllnessesByProcedure']),
    getIllnessesByProcedureEvent() {
      return this.getIllnessesByProcedure({ procedure: this.entityType, procedureId: this.$route.params.entityId })
        .then(() => {
          this.procedureIllnessesIdsInit = JSON.parse(JSON.stringify(this.procedureIllnessesIds));
        });
    },
    openDialog(dialogType) {
      this.dialogType = dialogType;
      this.isDialogOpen = true;
    },
    async update() {
      const addedEntity = _.difference(this[this.entityData.ids], this[this.entityData.idsInit]);
      const deletedEntity = _.difference(this[this.entityData.idsInit], this[this.entityData.ids]);
      this.isDialogOpen = false;

      if ((addedEntity && addedEntity.length) || (deletedEntity && deletedEntity.length)) {
        this.$store.dispatch('setLoader', true);

        try {
          if (addedEntity.length) {
            await this.linkOrUnlinkConnectionEvent('link', addedEntity);
          }
          if (deletedEntity.length) {
            await this.linkOrUnlinkConnectionEvent('unlink', deletedEntity);
          }
          this.$emit('get-procedure-event');
          this.$notify({ type: 'success', title: 'Saved' });
        } catch (e) {
          this.$notify({ type: 'warning', title: 'Save Failed' });
        } finally {
          this.$store.dispatch('setLoader', false);
        }
      }
    },
    async linkOrUnlinkConnectionEvent(methodType, addedOrDeletedEntity) {
      await this.linkOrUnlinkConnection({
        entityId: this.$route.params.entityId,
        methodType,
        data: { [this.entityData.entityIdsName]: addedOrDeletedEntity },
        entity: this.entityData.entity,
        entitiesString: this.entitiesString,
      });
    },
  }
}
</script>
