<template>
  <Entity :entityData="entityData"/>
</template>

<script>
import Entity from '@/components/Entities/Entity';
import { mapState } from 'vuex';

export default {
  props: ['entityId'],
  components: { Entity },
  computed: {
    ...mapState('cmsApiCatalogs', ['country']),
    entityData() {
      return {
        type: 'country',
        entitiesString: 'countries',
        entity: this.country.country,
        details: this.country.details,
        entityId: this.entityId,
        texts: {
          getMethod: 'getEntityTexts',
          saveMethod: 'saveEntityTexts',
          entitiesString: 'countries',
          defaultTexts: [
            { text: '', alias: 'txt_short'},
            { text: '', alias: 'covid'},
            { text: '', alias: 'visa'},
            { text: '', alias: 'transfer'},
            { text: '', alias: 'payment'},
            { text: '', alias: 'day_in_country_cost'},
            { text: '', alias: 'txt_after_3_clinics'},
          ],
        },
      };
    },
  },
}
</script>
