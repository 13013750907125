<template>
  <el-tabs v-model="activeLanguage">
    <el-tab-pane v-for="domain in domains"
                 :key="domain.language"
                 :label="`Text ${domain.language.toUpperCase()}`"
                 :name="domain.language">
      <card v-for="text in entityTexts"
            :title="text.alias"
            :key="text.alias">
        <ckeditor v-model="text.text"
                  v-if="activeLanguage === domain.language"
                  :config="{ allowedContent: true }"
        ></ckeditor>
        <base-button type="primary"
                     class="mt-16"
                     @click="save(text)">Save {{ text.alias }}</base-button>
        <base-button v-if="text.alias === 'txt_after_3_clinics'"
                     type="primary"
                     class="mt-16 ml-16"
                     @click="isDialogOpen = true">Translate {{ text.alias }}</base-button>
      </card>
    </el-tab-pane>
    <el-dialog :visible.sync="isDialogOpen">
      <h3>Please select languages for translate</h3>
      <el-checkbox-group v-model="translateLanguages" class="flex">
        <el-checkbox v-for="domain in domains" :key="domain.language" :label="domain.language" :value="domain.language" />                
      </el-checkbox-group>
      <base-button
                  type="primary"
                  class="mt-16"
                  @click="translate('txt_after_3_clinics')">Translate</base-button>
    </el-dialog>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex';
import APICmsCatalogs from '@/api/modules/cms-api-catalogs';

export default {
  props: ['data'],
  data: () => ({
    activeLanguage: null,
    entityTexts: [],
    isDialogOpen: false,
    translateLanguages: []
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['domains']),
    defaultEntityParams() {
      return {
        lang: this.activeLanguage,
        entitiesString: this.data.entitiesString,
        entityId: this.$route.params.entityId,
        forEntity: this.data.forEntity,
        forEntityId: this.data.forEntityId,
      };
    }
  },
  watch: {
    'data.forEntityId'() {
      this.getEntityTextsEvent(this.defaultEntityParams);
    },
    activeLanguage() {
      this.getEntityTextsEvent(this.defaultEntityParams);
    }
  },
  created() {
    this.activeLanguage = this.domains[0].language;
  },
  methods: {
    async save(data) {
      this.$store.dispatch('setLoader', true);

      try {
        await APICmsCatalogs[this.data.saveMethod]({
          ...this.defaultEntityParams,
          data,
        });
        this.$notify({ type: 'success', title: 'Saved' });
      } catch (e) {
        this.$notify({ type: 'warning', title: 'Save Failed' });
      } finally {
        this.$store.dispatch('setLoader', false);
      }
    },
    async translate(alias) {
      this.$store.dispatch('setLoader', true);

      try {
        await APICmsCatalogs[this.data.translateMethod]({
          ...this.defaultEntityParams,
          alias,
          target_locales: this.translateLanguages,
        });
        this.$notify({ type: 'success', title: 'Translated, wait a few minutes' });
      } catch (e) {
        console.log(e)
        this.$notify({ type: 'warning', title: 'Translate Failed' });
      } finally {
        this.$store.dispatch('setLoader', false);
        this.isDialogOpen = false;
      }
    },
    getEntityTextsEvent(params) {
      this.entityTexts = [];
      const defaultTexts = this.data.defaultTexts.map(el => ({ ...el, text: '' }));
      return APICmsCatalogs[this.data.getMethod](params).then((response) => {
        if (response.data && Array.isArray(response.data)) {
          this.entityTexts = defaultTexts.map(defaultText => {
            let findingEntityText = response.data.find(entityText => entityText.alias === defaultText.alias);
            return {
              alias: defaultText.alias,
              text: findingEntityText ? findingEntityText.text : '',
            };
          });
        } else {
          this.entityTexts = defaultTexts;
        }
      });
    },
  }
}
</script>
