<template>
  <card title="Clinic's location">
    <base-input label="Address RU" v-model="clinic.geo.address.ru" placeholder="Address RU"></base-input>
    <base-input label="Address EN" v-model="clinic.geo.address.en" placeholder="Address EN"></base-input>
    <el-select v-model="clinic.geo.country_code"
               placeholder="Country"
               no-data-text="No data"
               no-match-text="No match"
               @change="resetCity()"
               filterable
               value-key="code">
      <el-option v-for="county in countries"
                 :key="county.code"
                 :label="county.name.en"
                 :value="county.code">
      </el-option>
    </el-select>
    <el-select v-model="clinic.geo.city_id"
               placeholder="City"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               value-key="id">
      <el-option v-for="city in cities"
                 :key="city.id"
                 :label="city.name.en"
                 :value="city.id">
      </el-option>
    </el-select>
    <base-input label="Latitude" v-model="clinic.geo.latitude" placeholder="Latitude"></base-input>
    <base-input label="Longitude" v-model="clinic.geo.longitude" placeholder="Longitude"></base-input>
  </card>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    computed: {
      ...mapState('clinics', ['clinic']),
      ...mapState('cmsApiCatalogs', ['cities', 'countries']),
    },
    created() {
      this.getCountries({ perPage: 1500 });
      this.getCitiesEvent();
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getCities', 'getCountries']),
      getCitiesEvent() {
        return this.getCities({ perPage: 1500, country_iso_codes: [this.clinic.geo.country_code] });
      },
      resetCity() {
        this.clinic.geo.city_id = null;
        this.getCitiesEvent();
      },
    }
  }
</script>
