<template>
  <div>
    <card title="Filters">
      <div class="row">
        <div class="col-md-4 pr-md-1">
          <base-input placeholder="Doctor's name"
                      v-model="filters.name">
          </base-input>
        </div>
        <div class="col-md-4 px-md-1">
          <el-select v-model="filters.country_codes"
                     placeholder="Countries"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     multiple
                     value-key="id">
            <el-option v-for="country in countries"
                       :key="country.code"
                       :label="country.name.en"
                       :value="country.code">
            </el-option>
          </el-select>
        </div>
        <div class="col-md-2 px-md-1">
          <base-button @click="clearFilters()">Clear filters</base-button>
        </div>
      </div>
    </card>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

  export default {
    props: ['page'],
    data: () => ({
      filters: {
        name: '',
        country_codes: [],
      },
    }),
    computed: {
      ...mapState('cmsApiCatalogs', ['countries']),
    },
    watch: {
      filters: {
        immediate: true,
        deep: true,
        handler: 'setFilter',
      },
      page() {
        this.setFilter(this.page);
      },
    },
    created() {
      this.getCountries({ perPage: 1500 });
    },
    methods: {
      ...mapActions('doctors', ['getDoctors']),
      ...mapActions('cmsApiCatalogs', ['getCountries']),
      getDoctorsEvent(params) {
        this.$store.dispatch('setLoader', true);
        return this.getDoctors(params).finally(() => this.$store.dispatch('setLoader', false));
      },
      buildFilters() {
        return {
          name: '',
          country_codes: [],
        };
      },
      setFilter(currentPage) {
        let page = null;

        if (!isNaN(currentPage)) {
          page = currentPage;
        }

        const purifiedFilters = this.cleanFilters(this.filters);
        const params = { ...purifiedFilters };
        if (page) {
          params.page = page;
        } else {
          this.$emit('reset-doctor-page');
        }
        return this.getDoctorsEvent(params);
      },
      cleanFilters(filters) {
        return Object.keys(filters).reduce((acc, key) => {
          const value = filters[key];
          const isArray = Array.isArray(value);

          if (value || (isArray && value.length >= 1)) {
            acc[key] = value;
          }

          return acc;
        }, {});
      },
      clearFilters() {
        this.filters = this.buildFilters();
        this.$emit('reset-doctor-page');
      },
    }
  }
</script>
