<template>
  <div>
    <card title="Main specialties connection">
      <div class="single-page__specialties">
        <button @click="openDialog('Specialties')"><i class="fa fa-heartbeat"></i> Specialties</button>
        <button @click="openDialog('Illnesses')"><i class="fa fa-disease"></i> Illnesses</button>
        <button @click="openDialog('Diagnostics')"><i class="fa fa-stethoscope"></i> Diagnostics</button>
        <button @click="openDialog('Operations')"><i class="fa fa-user-md"></i> Operations</button>
      </div>
    </card>
    <el-dialog :visible.sync="isDialogOpen"
               :close-on-click-modal="false"
    >
      <template v-if="dialogType === 'Specialties'">
        <h3>Specialties</h3>
        <el-select v-model="fullItem.illness_kind_ids"
                   placeholder="Specialties"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in illnessKinds"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </template>
      <template v-if="dialogType === 'Illnesses'">
        <h3>Illnesses</h3>
        <el-select v-model="fullItem.illness_ids"
                   placeholder="Illnesses"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in illnesses"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </template>
      <template v-if="dialogType === 'Diagnostics'">
        <h3>Diagnostics</h3>
        <el-select v-model="fullItem.diagnostic_ids"
                   placeholder="Diagnostics"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in diagnostics"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </template>
      <template v-if="dialogType === 'Operations'">
        <h3>Operations</h3>
        <el-select v-model="fullItem.operation_ids"
                   placeholder="Operations"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in operations"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </template>
      <div class="el-dialog__btns">
        <button type="button" class="btn btn-primary" @click="update()">Save</button>
        <button type="button" class="btn btn-default" @click="isDialogOpen = false">Close</button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

  export default {
    props: ['fullItem', 'fullItemType'],
    data: () => ({
      isDialogOpen: false,
      dialogType: '',
      wrapper: null,
      elTags: null,
    }),
    created() {
      this.getIllnessKinds({ perPage: 1500 });
      this.getIllnesses({ perPage: 1500 });
      this.getDiagnostics({ perPage: 1500 });
      this.getOperations({ perPage: 1500 });
    },
    computed: {
      ...mapState('cmsApiCatalogs', ['illness-kinds', 'illnesses', 'diagnostics', 'operations']),
      ...mapState('clinics', ['clinic']),
      illnessKinds() {
        return this['illness-kinds'];
      }
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getIllnessKinds', 'getIllnesses', 'getDiagnostics', 'getOperations']),
      goToTextEntityBody(evt) {
        evt.stopPropagation();
        let target = evt.target;

        if (target.className === 'el-select__tags-text') {
          target = target.parentElement;
        }
        let index = [...this.elTags].indexOf(target);
        let entityIdsName, entity;

        switch (this.dialogType) {
          case 'Specialties':
            entityIdsName = 'illness_kind_ids';
            entity = 'illness-kinds';
            break;
          case 'Illnesses':
            entityIdsName = 'illness_ids';
            entity = 'illnesses';
            break;
          case 'Diagnostics':
            entityIdsName = 'diagnostic_ids';
            entity = 'diagnostics';
            break;
          case 'Operations':
            entityIdsName = 'operation_ids';
            entity = 'operations';
            break;
        }

        let entityId = this.fullItem[entityIdsName][index];

        if (entityId) {
          this.$router.push({
            name: 'ClinicEntityTexts',
            params: {
              clinicId: this.clinic.id,
              entity,
              entityId,
            }
          })
        }
      },
      goToTextEntity() {
        if (this.fullItemType === 'clinic') {
          this.wrapper = document.querySelector('.el-select__tags span');
          this.elTags = document.getElementsByClassName('el-tag');

          this.wrapper.addEventListener('click', this.goToTextEntityBody);
        }
      },
      openDialog(dialogType) {
        this.dialogType = dialogType;
        this.isDialogOpen = true;
        this.wrapper = null;

        setTimeout(() => this.goToTextEntity(), 500);
      },
      update() {
        this.isDialogOpen = false;
        this.$emit('update');
      }
    }
  }
</script>
