<template>
  <card title="Filters">
    <div class="row">
      <div class="col-md-3">
        <el-select v-model="filters.operation_ids"
                   placeholder="Operations"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in operations"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="col-md-3">
        <el-select v-model="filters.illness_ids"
                   placeholder="Illnesses"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in illnesses"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </div>
      <div class="col-md-3">
        <el-select v-model="filters.diagnostic_ids"
                   placeholder="Diagnostics"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="item in diagnostics"
                     :key="item.id"
                     :label="item.name.en"
                     :value="item.id">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <el-select v-model="filters.country_codes"
                   placeholder="Countries"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="country in countries"
                     :key="country.code"
                     :label="country.name.en"
                     :value="country.code">
          </el-option>
        </el-select>
      </div>
      <div class="col-md-3">
        <base-input v-model="clinicId"
                    @focusout="filterByClinicId()"
                    placeholder="Select clinic ID">
        </base-input>
      </div>
      <div class="col-md-3">
        <base-input v-model="feedbackId"
                    @focusout="filterByFeedbackId()"
                    placeholder="Select Order ID">
        </base-input>
      </div>
    </div>
    <div class="row">
      <div class="col-md-3">
        <el-select v-model="filters.approved"
                   placeholder="Approved/Not approved/All reviews"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable>
          <el-option value="true" label="Approved"></el-option>
          <el-option value="false" label="Not approved"></el-option>
          <el-option :value="[]" label="All reviews"></el-option>
        </el-select>
      </div>
      <div class="col-md-2">
        <base-button @click="clearFilters()">Clear filters</base-button>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: ['page'],
  data: () => ({
    clinicId: null,
    feedbackId: null,
    filters: {
      operation_ids: [],
      illness_ids: [],
      diagnostic_ids: [],
      country_codes: [],
      clinic_ids: [],
      feedback_ids: [],
      approved: [],
    },
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['countries', 'illnesses', 'operations', 'diagnostics']),
  },
  watch: {
    filters: {
      immediate: true,
      deep: true,
      handler: 'setFilter',
    },
    page() {
      this.setFilter(this.page);
    },
  },
  created() {
    this.getCountries({ perPage: 1500 });
    this.getIllnesses({ perPage: 1500 });
    this.getOperations({ perPage: 1500 });
    this.getDiagnostics({ perPage: 1500 });
  },
  methods: {
    ...mapActions('reviews', ['getReviews']),
    ...mapActions('cmsApiCatalogs', ['getCountries', 'getIllnesses', 'getOperations', 'getDiagnostics']),
    getReviewsEvent(params) {
      this.$store.dispatch('setLoader', true);
      return this.getReviews(params).finally(() => this.$store.dispatch('setLoader', false));
    },
    filterByClinicId() {
      this.filters.clinic_ids = this.clinicId ? [this.clinicId] : [];
    },
    filterByFeedbackId() {
      this.filters.feedback_ids = this.feedbackId ? [this.feedbackId] : [];
    },
    buildFilters() {
      return {
        operation_ids: [],
        illness_ids: [],
        diagnostic_ids: [],
        country_codes: [],
        clinic_ids: [],
        feedback_ids: [],
        approved: [],
      };
    },
    setFilter(currentPage) {
      let page = null;

      if (!isNaN(currentPage)) {
        page = currentPage;
      }

      const purifiedFilters = this.cleanFilters(this.filters);
      const params = { ...purifiedFilters };
      if (page) {
        params.page = page;
      } else {
        this.$emit('reset-reviews-page');
      }
      return this.getReviewsEvent(params);
    },
    cleanFilters(filters) {
      return Object.keys(filters).reduce((acc, key) => {
        const value = filters[key];
        const isArray = Array.isArray(value);

        if (value || (isArray && value.length >= 1)) {
          acc[key] = value;
        }

        return acc;
      }, {});
    },
    clearFilters() {
      this.clinicId = null;
      this.feedbackId = null;
      this.filters = this.buildFilters();
      this.$emit('reset-reviews-page');
    },
  }
}
</script>
