<template>
  <card title="Status">
    <label>Status</label>
    <el-select v-model="status"
               placeholder="Status"
               reserve-keyword
               value-key="id">
      <el-option v-for="item in statuses"
                 :key="item"
                 :label="item"
                 :value="item">
      </el-option>
    </el-select>
    <base-button type="primary"
                 @click="saveStatus()">Save status</base-button>
  </card>
</template>

<script>
import APICmsCatalogs from '@/api/modules/cms-api-catalogs';
import { mapState } from 'vuex';

export default {
  data: () => ({
    status: null,
    statuses: ['DRAFT', 'APPROVED'],
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['clinicsMediaItem']),
  },
  mounted() {
    this.status = this.clinicsMediaItem?.status;
  },
  methods: {
    saveStatus() {
      this.$store.dispatch('setLoader', true);
      return APICmsCatalogs.saveClinicMediaStatus({
        mediaId: this.clinicsMediaItem.id,
        status: this.status,
      }).then((response) => response?.data?.error ?
        alert(response.data.error?.data?.description) :
        this.$notify({ type: 'success', title: 'Saved' }))
        .catch(() => this.$notify({ type: 'warning', title: 'Failed' }))
        .finally(() => this.$store.dispatch('setLoader', false));
    },
  }
};
</script>
