<template>
  <div class="clinic-video">
    <div class="row">
      <div class="col-md-6">
        <card :title="clinic.name && clinic.name.en">
          <base-input label="Video author" v-model="userName" placeholder="Video author"></base-input>
          <base-input label="Link" v-model="clinicsMediaItem.link" placeholder="Link" :class="{'has-danger': isLinkError}"></base-input>
          <base-input label="Video preview" v-model="videoPreview" placeholder="Preview image link"></base-input>
          <base-input label="User country" v-model="userCountry" placeholder="User country"></base-input>
        </card>
        <card title="Medical need">
          <label>Illnesses</label>
          <el-select v-model="mediaIllnesses"
                     placeholder="Illnesses"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     multiple
                     value-key="id">
            <el-option v-for="item in illnesses"
                       :key="item.id"
                       :label="item.name.en"
                       :value="item.id">
            </el-option>
          </el-select>
          <label>Diagnostics</label>
          <el-select v-model="mediaDiagnostics"
                     placeholder="Diagnostics"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     multiple
                     value-key="id">
            <el-option v-for="item in diagnostics"
                       :key="item.id"
                       :label="item.name.en"
                       :value="item.id">
            </el-option>
          </el-select>
          <label>Operations</label>
          <el-select v-model="mediaOperations"
                     placeholder="Operations"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     multiple
                     value-key="id">
            <el-option v-for="item in operations"
                       :key="item.id"
                       :label="item.name.en"
                       :value="item.id">
            </el-option>
          </el-select>
          <label>Illness Kind</label>
          <el-select v-model="mediaIllnessKind"
                     placeholder="Illness kind"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword
                     value-key="id">
            <el-option v-for="item in illnessKinds"
                       :key="item.id"
                       :label="item.name.en"
                       :value="item.id">
            </el-option>
          </el-select>
        </card>
      </div>
      <div class="col-md-6">
        <ClinicVideoDescription v-if="domains && domains.length"/>
        <ClinicVideosStatus v-show="clinicsMediaItem.id"/>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <base-button type="primary" class="mr-16" @click="saveVideoInfo()">Save video info</base-button>
        <base-button type="simple" @click="$emit('closeMediaItem')">Back</base-button>
      </div>
    </div>
  </div>
</template>

<script>
import APICmsCatalogs from '@/api/modules/cms-api-catalogs';
import ClinicVideoDescription from '@/components/Clinics/video/ClinicVideoDescription';
import ClinicVideosStatus from '@/components/Clinics/video/ClinicVideosStatus';
import { mapState, mapActions } from 'vuex';

export default {
  props: ['isNewVideo'],
  components: { ClinicVideoDescription, ClinicVideosStatus },
  data: () => ({
    videoPreview: '',
    userName: '',
    userCountry: '',
    mediaIllnesses: [],
    mediaDiagnostics: [],
    mediaOperations: [],
    mediaIllnessKind: '',
    isLinkError: false,
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['clinicsMediaItem', 'illnesses', 'diagnostics', 'operations', 'domains', 'illness-kinds']),
    ...mapState('clinics', ['clinic']),
    illnessKinds() {
      return this['illness-kinds'];
    },
  },
  watch: {
    'clinicsMediaItem.link'(v) {
      if (v) {
        this.isLinkError = false;
      }
    },
  },
  mounted() {
    this.initMedia();
  },
  methods: {
    ...mapActions('cmsApiCatalogs', ['getClinicsMedia']),
    saveVideoInfo() {
      this.isLinkError = !this.clinicsMediaItem.link;

      if (!this.isLinkError) {
        this.$store.dispatch('setLoader', true);
        return APICmsCatalogs[this.isNewVideo ? 'saveClinicMedia' : 'putClinicMedia']({
          id: this.isNewVideo ? this.$route.params.clinicId : this.clinicsMediaItem.id,
          data: {
            ...this.clinicsMediaItem,
            link: this.clinicsMediaItem.link.trim(),
            type: 'video',
            tags: [
              ...this.mediaIllnesses.map((id) => `illness_id:${id}`),
              ...this.mediaDiagnostics.map((id) => `diagnostic_id:${id}`),
              ...this.mediaOperations.map((id) => `operation_id:${id}`),
              `illness_kind_id:${this.mediaIllnessKind}`,
              `country:${this.clinic?.geo?.country_code}`,
              `user_name:${this.userName}`,
              `user_country:${this.userCountry}`,
              `preview:${this.videoPreview}`,
            ],
          }
        }).then((response) => {
          if (response?.data?.error) {
            alert(response.data.error?.data?.code);
          } else {
            this.$emit('closeMediaItem');
            this.$notify({ type: 'success', title: 'Saved' });
          }
        }).catch(() => this.$notify({ type: 'warning', title: 'Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      }
      return null;
    },
    initMedia() {
      if (this.clinicsMediaItem?.tags?.length) {
        this.userName = this.findUserInfo('user_name');
        this.videoPreview = this.findUserInfo('preview');
        this.userCountry = this.findUserInfo('user_country');
        this.mediaIllnesses = this.findEntity('illness_id');
        this.mediaDiagnostics = this.findEntity('diagnostic_id');
        this.mediaOperations = this.findEntity('operation_id');
        this.mediaIllnessKind = this.findEntity('illness_kind_id');
      }
    },
    findUserInfo(type) {
      const userEntity = this.clinicsMediaItem.tags.find((tag) => tag.includes(type));
      if (userEntity) {
        return userEntity.slice(userEntity.indexOf(':') + 1);
      }
      return null;
    },
    findEntity(type) {
      return this.clinicsMediaItem.tags.filter((tag) => tag.includes(type)).map((entity) => +entity.slice(entity.indexOf(':') + 1));
    },
  }
};
</script>
