<template>
  <div class="clinic" v-if="clinic && clinic.id">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Main info" name="mainInfo">
        <div class="row">
          <div class="col-md-6">
            <SinglePageConnection :fullItem="clinic" @update="save()" fullItemType="clinic"/>
            <SinglePageNameInfo :fullItem="clinic" type="clinic"/>
            <ClinicLocation v-if="clinic.geo"/>
            <ClinicAccreditations/>
            <ClinicFlags v-if="clinic.id !== 435"/>
          </div>
          <div class="col-md-6">
            <ClinicRating v-if="clinic.rating"/>
            <ClinicStatistics v-if="clinic.statistics"/>
            <ClinicUrls v-if="clinic.urls"/>
          </div>
        </div>
      </el-tab-pane>
      <template v-if="domains && Object.keys(domains).length">
        <el-tab-pane label="Short description" name="shortDescription">
          <ClinicDescriptionComponent descriptionType="short_description"/>
        </el-tab-pane>
        <el-tab-pane label="Full description" name="fullDescription">
          <ClinicDescriptionComponent descriptionType="description"/>
        </el-tab-pane>
        <el-tab-pane label="Clinic's texts" name="texts">
          <ClinicTexts/>
        </el-tab-pane>
      </template>
      <el-tab-pane label="Clinic's images" name="images">
        <ClinicsImages @get-clinic-event="getClinicEvent()"/>
      </el-tab-pane>
      <el-tab-pane label="Videos" name="video">
        <ClinicVideos/>
      </el-tab-pane>
      <div class="row">
        <div class="col-12">
          <base-button type="primary" v-if="isSaveBtnVisible" @click="save()">Update clinic</base-button>
        </div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import SinglePageConnection from '@/components/SinglePage/SinglePageConnection';
import SinglePageNameInfo from '@/components/SinglePage/SinglePageNameInfo';
import ClinicLocation from '@/components/Clinics/ClinicLocation';
import ClinicAccreditations from '@/components/Clinics/ClinicAccreditations';
import ClinicRating from '@/components/Clinics/ClinicRating';
import ClinicStatistics from '@/components/Clinics/ClinicStatistics';
import ClinicUrls from '@/components/Clinics/ClinicUrls';
import ClinicDescriptionComponent from '@/components/Clinics/ClinicDescriptionComponent';
import ClinicFlags from '@/components/Clinics/ClinicFlags';
import ClinicsImages from '@/components/Clinics/ClinicsImages';
import ClinicTexts from '@/components/Clinics/ClinicTexts';
import ClinicVideos from '@/components/Clinics/video/ClinicVideos';
import { mapActions, mapState, mapMutations } from "vuex";

  export default {
    props: ['clinicId'],
    components: {
      SinglePageConnection,
      SinglePageNameInfo,
      ClinicLocation,
      ClinicAccreditations,
      ClinicRating,
      ClinicStatistics,
      ClinicUrls,
      ClinicDescriptionComponent,
      ClinicFlags,
      ClinicsImages,
      ClinicTexts,
      ClinicVideos,
    },
    data: () => ({
      activeName: 'mainInfo',
    }),
    computed: {
      ...mapState('clinics', ['clinic']),
      ...mapState('cmsApiCatalogs', ['domains']),
      isSaveBtnVisible() {
        switch (this.activeName) {
          case 'texts':
          case 'video':
            return false;
        }
        return true;
      },
    },
    created() {
      this.getClinicEvent();
    },
    beforeDestroy() {
      this.SET_DATA({ type: 'clinic', data: {} });
    },
    methods: {
      ...mapActions('clinics', ['getClinic', 'updateClinic']),
      ...mapMutations('clinics', ['SET_DATA']),
      async getClinicEvent() {
        this.$store.dispatch('setLoader', true);

        await this.getClinic(this.clinicId);
        this.$store.dispatch('setLoader', false);
      },
      save() {
        this.$store.dispatch('setLoader', true);
        const name = {};

        if (this.clinic.name && Object.keys(this.clinic.name).length) {
          Object.keys(this.clinic.name).forEach(lang => {
            if (this.clinic.name[lang]) {
              name[lang] = this.clinic.name[lang];
            }
          });
        }

        return this.updateClinic({ data: { ...this.clinic, name }, clinicId: this.clinicId })
          .then(() => this.$notify({ type: 'success', title: 'Saved' }))
          .catch(() => this.$notify({ type: 'warning', title: 'Save Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      },
    }
  }
</script>
