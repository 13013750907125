<template>
  <Entity :entityData="entityData"/>
</template>

<script>
import Entity from '@/components/Entities/Entity';
import { mapState } from 'vuex';

export default {
  props: ['entityId'],
  components: { Entity },
  computed: {
    ...mapState('cmsApiCatalogs', ['operation']),
    entityData() {
      return {
        type: 'operation',
        entitiesString: 'operations',
        entity: this.operation.operation,
        details: this.operation.details,
        entityId: this.entityId,
        texts: {
          getMethod: 'getEntityTexts',
          saveMethod: 'saveEntityTexts',
          entitiesString: 'operations',
          defaultTexts: [
            { text: '', alias: 'guide_mc'},
            { text: '', alias: 'txt_short'},
            { text: '', alias: 'txt_long'},
            { text: '', alias: 'txt_procedure_3_clinics'},
            { text: '', alias: 'trust_block'},
            { text: '', alias: 'medical_analysis'},
            { text: '', alias: 'txt_price'},
            { text: '', alias: 'overview'}
          ],
        },
      };
    },
  },
}
</script>
