<template>
  <div class="row">
    <div class="col-12">
      <card title="Doctors">
        <table class="table table-responsive" v-if="doctors && doctors.length">
          <tr>
            <th>ID</th>
            <th></th>
            <th>Name</th>
            <th>Country</th>
            <th>Description</th>
            <th>Start of work</th>
            <th>Illnesses</th>
            <th>Operations</th>
            <th>Is hide</th>
            <th></th>
          </tr>
          <tr v-for="doctor in doctors" :key="doctor.id">
            <td>{{ doctor.id }}</td>
            <td>
              <img :src="showDoctorImg(doctor.photo)" class="doctors__ava">
            </td>
            <td>
              <a :href="`https://en.bookimed.com/doctor/${doctor.alias.en}/`"
                 target="_blank">{{ doctor.name ? doctor.name.en : '-' }}</a>
            </td>
            <td>{{doctor.clinic ? getCountryName(doctor.clinic.country_iso) : '-'}}</td>
            <td v-html="getDoctorShortDescription(doctor.doctor_texts)"></td>
            <td>{{ doctor.year_start_work || '-' }}</td>
            <td>
              <template v-if="getFullDoctorSicks(doctor.illness_ids, 'illnesses').length">
                  <span v-for="(doctorIllness, index) in getFullDoctorSicks(doctor.illness_ids, 'illnesses')" :key="doctorIllness.id">
                    {{doctorIllness.name ? doctorIllness.name.en : ''}}<template v-if="getFullDoctorSicks(doctor.illness_ids, 'illnesses').length - 1 !== index">,</template>
                  </span>
              </template>
            </td>
            <td>
              <template v-if="getFullDoctorSicks(doctor.operation_ids, 'operations').length">
                  <span v-for="(doctorOperation, index) in getFullDoctorSicks(doctor.operation_ids, 'operations')" :key="doctorOperation.id">
                    {{doctorOperation.name ? doctorOperation.name.en : ''}}<template v-if="getFullDoctorSicks(doctor.operation_ids, 'operations').length - 1 !== index">,</template>
                  </span>
              </template>
            </td>
            <td>
              {{isHideDoctor(doctor.flags) ? 'Yes' : 'No'}}
            </td>
            <td>
              <button class="btn btn-primary btn-link" @click="editItem(doctor.id)">
                <i class="tim-icons icon-pencil"></i>
              </button>
            </td>
          </tr>
        </table>
        <div class="card-body" v-else>
          No data
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

  export default {
    computed: {
      ...mapState('cmsApiCatalogs', ['countries', 'illnesses', 'operations']),
      ...mapState('doctors', ['doctors']),
    },
    created() {
      this.getIllnesses({ perPage: 1500 });
      this.getOperations({ perPage: 1500 });
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getIllnesses', 'getOperations']),
      editItem(doctorId) {
        let routeData = this.$router.resolve({ name: 'Doctor', params: { doctorId } });
        window.open(routeData.href, '_blank');
      },
      showDoctorImg(photoUrl) {
        if (photoUrl) {
          const doctorImgHash = photoUrl.slice(photoUrl.lastIndexOf('/') + 1)
          return `https://img.bookimed.com/doctor/${doctorImgHash}.jpeg`;
        }
      },
      getFullDoctorSicks(sickIds, listType) {
        if (sickIds && sickIds.length) {
          return this[listType].filter(item => sickIds.includes(item.id));
        }
        return [];
      },
      getDoctorShortDescription(doctorTexts) {
        let shortDescription = doctorTexts.find(item => item.alias === 'short_description');

        if (shortDescription && shortDescription.text) {
          return shortDescription.text.en;
        }
        return '-';
      },
      getCountryName(countryIso) {
        const findingCountry = this.countries.find(country => country.code === countryIso);
        return findingCountry && findingCountry.name ? findingCountry.name.en : '-';
      },
      isHideDoctor(flags) {
       return flags && flags.length && flags.find(item => item.name === 'is_hide') ? flags.find(item => item.name === 'is_hide').value : false;
      }
    }
  }
</script>

<style lang="scss">
  .doctors{
    &__ava{
      min-width: 70px;
      width: 70px;
    }
  }
</style>
