<template>
  <card title="Urls">
    <base-input label="Facebook url" v-model="clinic.urls.facebook_url" placeholder="Facebook url"></base-input>
    <base-input label="Google url" v-model="clinic.urls.google_url" placeholder="Google url"></base-input>
    <base-input label="Site url" v-model="clinic.urls.site_url" placeholder="Site url"></base-input>
    <base-input label="Trustpilot url" v-model="clinic.urls.trustpilot_url" placeholder="Trustpilot url"></base-input>
    <base-input label="Wiki url" v-model="clinic.urls.wiki_url" placeholder="Wiki url"></base-input>
  </card>
</template>

<script>
  import { mapState } from 'vuex';

  export default {
    computed: {
      ...mapState('clinics', ['clinic']),
    },
  }
</script>
