<template>
  <div>
    <card title="Select country">
      <el-select v-model="countryId"
                 placeholder="Countries"
                 no-data-text="No data"
                 no-match-text="No match"
                 filterable
                 reserve-keyword
                 value-key="id">
        <el-option v-for="country in countries"
                   :key="country.id"
                   :label="country.name.en"
                   :value="country.id">
        </el-option>
      </el-select>
    </card>
    <EntityTexts v-if="countryId" :data="texts"/>
  </div>
</template>

<script>
  import EntityTexts from '@/components/Entities/EntityTexts';
  import { mapActions, mapState } from 'vuex';

  export default {
    props: ['entitiesString'],
    data: () => ({
      countryId: null,
    }),
    components: {
      EntityTexts,
    },
    computed: {
      ...mapState('cmsApiCatalogs', ['countries']),
      defaultTexts() {
        if (this.$route.name === 'illness-kind') {
          return [
            { text: '', alias: 'txt_before_clinics'},
            { text: '', alias: 'txt_after_3_clinics'},
            { text: '', alias: 'txt_clinics'},
            { text: '', alias: 'txt_price'},
          ];
        } else {
          return [
            { text: '', alias: 'txt_country'},
            { text: '', alias: 'txt_clinics'},
            { text: '', alias: 'txt_after_3_clinics'},
            { text: '', alias: 'txt_price'},
            { text: '', alias: 'trust_block'},
          ];
        }
      },
      texts() {
        let defaultTexts = [...this.defaultTexts];
        if (this.$route.name === 'operation' || this.$route.name === 'illness') {
          defaultTexts.push({ text: '', alias: 'pros_cons_mc' });
          defaultTexts.push({ text: '', alias: 'country_hub' });
        }
        if (this.$route.name === 'operation') {
          defaultTexts.push({ text: '', alias: 'itinerary' });
        }

        return {
          getMethod: 'getEntityTextsConnection',
          saveMethod: 'saveEntityTextsConnection',
          translateMethod: 'translateEntityTextsConnection',
          forEntity: 'countries',
          forEntityId: this.countryId,
          entitiesString: this.$route.name === 'illness-kind' ? 'illnesses_kinds' : this.entitiesString,
          defaultTexts,
        };
      },
    },
    created() {
      this.getCountries({ perPage: 1500 });
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getCountries']),
    }
  };
</script>
