<template>
  <el-tabs v-model="activeLanguage">
    <el-tab-pane v-for="domain in domains"
                 :key="domain.language"
                 :label="`Text ${domain.language.toUpperCase()}`"
                 :name="domain.language">
      <card v-for="text in domainTexts"
            :title="text.alias"
            :key="text.alias">
        <base-input v-if="text.alias === 'video_review' || text.alias === 'video'">
            <textarea rows="4" cols="80"
                      class="form-control"
                      :placeholder="text.alias"
                      v-model="text.text"
            ></textarea>
        </base-input>
        <ckeditor v-else-if="activeLanguage === domain.language"
                  v-model="text.text"
                  :config="{ allowedContent: true }">
        </ckeditor>
      </card>
      <base-button type="primary"
                   class="mt-16"
                   @click="save()">Save clinic's texts</base-button>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import APIClinics from '@/api/modules/clinics';
import { mapState } from 'vuex';

  export default {
    data: () => ({
      activeLanguage: null,
      domainTexts: [],
      defaultTexts: [
        { alias: 'video', texts: ''},
        { alias: 'video_review', texts: ''},
        { alias: 'txt_official_represent', texts: ''},
      ],
    }),
    computed: {
      ...mapState('cmsApiCatalogs', ['domains']),
    },
    watch: {
      activeLanguage() {
        this.getDomainTextsEvent({ lang: this.activeLanguage, clinicId: this.$route.params.clinicId });
      },
    },
    created() {
      this.activeLanguage = this.domains[0].language;
    },
    methods: {
      async save(data) {
        this.$store.dispatch('setLoader', true);

        try {
          await APIClinics.updateClinicDomainTexts({
            lang: this.activeLanguage,
            clinicId: this.$route.params.clinicId,
            data: {
              texts: this.domainTexts,
            },
          });
          this.$notify({ type: 'success', title: 'Saved' });
        } catch (e) {
          this.$notify({ type: 'warning', title: 'Save Failed' });
        } finally {
          this.$store.dispatch('setLoader', false);
        }
      },
      getDomainTextsEvent(params) {
        this.domainTexts = [];
        this.$store.dispatch('setLoader', true);
        return APIClinics.getClinicDomainTexts(params).then((response) => {
          if (response.data && Array.isArray(response.data.texts)) {
            this.domainTexts = this.defaultTexts.map(defaultText => {
              let findingEntityText = response.data.texts.find(domainText => domainText.alias === defaultText.alias);
              return {
                alias: defaultText.alias,
                text: findingEntityText ? findingEntityText.text : '',
              };
            });
          } else {
            this.domainTexts = this.defaultTexts;
          }
        }).finally(() => this.$store.dispatch('setLoader', false));
      },
    },
  };
</script>
