<template>
  <card title="Countries">
    <el-select v-model="entity.country"
               placeholder="Countries"
               no-data-text="No data"
               no-match-text="No match"
               filterable
               reserve-keyword
               value-key="id">
      <el-option v-for="item in countries"
                 :key="item.id"
                 :label="item.name.en"
                 :value="item">
      </el-option>
    </el-select>
  </card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  props: ['entity'],
  computed: {
    ...mapState('cmsApiCatalogs', ['countries']),
  },
  created() {
    this.getCountries({ perPage: 1500 });
  },
  methods: {
    ...mapActions('cmsApiCatalogs', ['getCountries'])
  }
}
</script>
