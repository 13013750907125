<template>
  <div class="review__sicks">
    <card title="Order's illness" v-if="order.illness_id && getSicks([order.illness_id], 'illnesses').length">
        <span v-for="(illness) in getSicks([order.illness_id], 'illnesses')" :key="illness.id">
          {{illness.name ? illness.name.en : ''}}
        </span>
    </card>
    <card title="Order's operations" v-if="getSicks(order.operation_ids, 'operations').length">
      <span v-for="(operation, index) in getSicks(order.operation_ids, 'operations')" :key="operation.id">
        {{operation.name ? operation.name.en : ''}}
       <template v-if="getSicks(order.operation_ids, 'operations').length - 1 !== index">,</template>
      </span>
    </card>
    <card title="Order's diagnostics" v-if="getSicks(order.diagnostic_ids, 'diagnostics').length">
        <span v-for="(diagnostic, index) in getSicks(order.diagnostic_ids, 'diagnostics')" :key="diagnostic.id">
          {{diagnostic.name ? diagnostic.name.en : ''}}<template v-if="getSicks(order.diagnostic_ids, 'diagnostics').length - 1 !== index">,</template>
        </span>
    </card>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['order'],
  computed: {
    ...mapState('cmsApiCatalogs', ['illnesses', 'operations', 'diagnostics']),
  },
  created() {
    this.getIllnesses({ perPage: 1500 });
    this.getOperations({ perPage: 1500 });
    this.getDiagnostics({ perPage: 1500 });
  },
  methods: {
    ...mapActions('cmsApiCatalogs', ['getCountries', 'getIllnesses', 'getOperations', 'getDiagnostics']),
    getSicks(sickIds, listType) {
      if (sickIds && sickIds.length) {
        return this[listType].filter(item => sickIds.includes(item.id));
      }
      return [];
    },
  }
}
</script>

<style lang="scss">
.review{

}
</style>
