<template>
  <card title="Bookimed chat-api">
    <div class="row">
      <div class="col-12">
        <el-switch
          v-model="eventType"
          size="large"
          active-color="#a3cc0e"
          inactive-color="#a3cc0e"
          active-text="AI tools"
          inactive-text="AI bot"
        />
      </div>
    </div>
    <div class="row mt-16">
      <div class="col-md-4 pr-md-1">
        <div v-if="!isNewAgent">
          <label>Agent name</label>
          <el-select v-model="agentData.agent_name"
                     placeholder="Agent name"
                     @change="getPromptsAgentDataEvent()"
                     no-data-text="No data"
                     no-match-text="No match"
                     filterable
                     reserve-keyword>
            <el-option v-for="agentName in agentsNamesList"
                       :key="agentName"
                       :label="agentName"
                       :value="agentName">
            </el-option>
          </el-select>
        </div>
        <div class="agent-bot__adding">
          <base-input label="New agent name"
                      v-model="newAgent"
                      placeholder="New agent name">
          </base-input>
          <base-button type="primary btn-sm"
                       v-if="!isNewAgent"
                       @click="setNewAgent()"
                       :disabled="!newAgent">+</base-button>
        </div>
      </div>
      <div class="col-md-4 px-md-1">
        <label>Model name</label>
        <el-select v-model="agentData.model_name"
                   placeholder="Model name"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword>
          <el-option v-for="modelName in modelsNamesList"
                     :key="modelName"
                     :label="modelName"
                     :value="modelName">
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2 px-md-1">
        <label>Temperature</label>
        <el-select v-model="agentData.temperature"
                   placeholder="Temperature"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword>
          <el-option v-for="temperature in temperatures"
                     :key="temperature"
                     :label="temperature"
                     :value="temperature">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <label>Prompts editor</label>
        <el-input
          type="textarea"
          :autosize="{ minRows: 13 }"
          v-model="agentData.prompt"
          placeholder="Some prompt texts"
        ></el-input>
      </div>
    </div>
    <div class="row mt-16">
      <div class="col-12">
        <base-button type="primary"
                     @click="save()"
                     :disabled="!agentData.agent_name"
        >Save changes</base-button>
      </div>
    </div>
  </card>
</template>

<script>
  import { mapState, mapActions } from 'vuex';

  export default {
    data: () => ({
      eventType: false,
      newAgent: '',
      temperatures: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      agentData: {},
      isNewAgent: false,
      initAgentData: {
        agent_name: null,
        prompt: '',
        model_name: null,
        temperature: 0.5,
      },
    }),
    computed: {
      ...mapState('chatApi', ['agentsNames', 'modelsNames', 'agentDataByName']),
      ...mapState('offersClusters', ['agentsNamesOffers', 'modelsNamesOffers', 'agentDataByNameOffers']),
      agentsNamesList() {
        return this.eventType ? this.agentsNamesOffers : this.agentsNames;
      },
      modelsNamesList() {
        return this.eventType ? this.modelsNamesOffers : this.modelsNames;
      },
      agentDataByNameState() {
        return this.eventType ? this.agentDataByNameOffers : this.agentDataByName;
      },
    },
    watch: {
      eventType() {
        this.agentData = JSON.parse(JSON.stringify(this.initAgentData));
        if (this.eventType) {
          this.getAgentsNamesOffers();
          this.getModelsNameOffers();
        } else {
          this.getAgentsNames();
          this.getModelsName();
        }
      }
    },
    created() {
      this.getAgentsNames();
      this.getModelsName();
      this.agentData = JSON.parse(JSON.stringify(this.initAgentData));
    },
    methods: {
      ...mapActions('chatApi', ['getAgentsNames', 'getModelsName', 'getAgentDataByName', 'updateAgent']),
      ...mapActions('offersClusters', ['getAgentsNamesOffers', 'getModelsNameOffers', 'getAgentDataByNameOffers', 'updateAgentOffers']),
      getPromptsAgentDataEvent() {
        this.$store.dispatch('setLoader', true);
        return this[this.eventType ? 'getAgentDataByNameOffers' : 'getAgentDataByName']({ agent_name: this.agentData.agent_name })
          .then(() => {
            this.agentData = this.agentDataByNameState;
          }).catch(() => this.$notify({ type: 'warning', title: 'Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      },
      setNewAgent() {
        if (this.agentsNamesList.includes(this.newAgent) && !window.confirm(`Do you want to rewrite ${this.newAgent}?`)) {
          return null;
        }
        this.isNewAgent = true;
        this.agentData = { ...this.initAgentData, agent_name: this.newAgent };
      },
      save() {
        this.$store.dispatch('setLoader', true);
        return this[this.eventType ? 'updateAgentOffers' : 'updateAgent'](this.agentData)
          .then(() => {
            if (this.isNewAgent) {
              this.isNewAgent = false;
              this.newAgent = '';
            }
          }).catch(() => this.$notify({ type: 'warning', title: 'Failed' }))
          .finally(() => this.$store.dispatch('setLoader', false));
      },
    }
  }
</script>

<style lang="scss">
  .agent-bot__adding{
    display: flex;
    align-items: flex-end;
    gap: 16px;
    .form-group{
      width: 100%;
    }
    button{
      height: 40px;
      margin-bottom: 10px;
    }
  }
</style>
