<template>
  <div>
    <EntitiesFilters :page="page"
                   :entityData="entityData"
                   @reset-page="page = 1"/>
    <EntitiesTable :entityData="entityData"/>
    <el-pagination
      v-if="pageCount > 1"
      background
      prev-text="Previous"
      next-text="Next"
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      :current-page.sync="page"
      class="text-center mt-20"
      :page-count="pageCount">
    </el-pagination>
  </div>
</template>

<script>
import EntitiesFilters from '@/components/Entities/EntitiesFilters';
import EntitiesTable from '@/components/Entities/EntitiesTable';

export default {
  props: ['entityData'],
  components: {
    EntitiesFilters,
    EntitiesTable,
  },
  data: () => ({
    page: 1,
    pageSize: 20,
  }),
  computed: {
    pageCount() {
      return this.entityData.meta['total_page'];
    }
  },
};
</script>
