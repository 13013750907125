<template>
  <div>
    <main class="help-center card">
      <div class="card-body">
        <div class="help-center__title">
          <span>List of questions</span>
          <router-link :to="{ path: 'create' }" class="btn btn-primary"
            >Create</router-link
          >
        </div>
        <el-table :data="questionsList.questions">
          <el-table-column
            fixed
            :prop="`question[${item.language}]`"
            :label="`Title ${item.language.toUpperCase()}`"
            v-for="item in titleLanguages"
            :key="item.language"
          />
          <el-table-column fixed="right" width="200">
            <template slot-scope="scope">
              <div class="cell">
                <el-button size="mini" @click="handleEdit(scope.row.id)"
                  >Edit</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="handleDelete(scope.row.id)"
                  >Delete</el-button
                >
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </main>
    <el-pagination
      v-if="questionsList.metadata.total_count"
      style="text-align:center"
      :page-size="30"
      layout="prev, pager, next"
      :total="questionsList.metadata.total_count"
      :current-page="page"
      @current-change="nextPage"
      class="text-center mt-20"
      background
    ></el-pagination>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

export default {
  data: () => ({
    page: 1
  }),
  computed: {
    ...mapState("cmsApiCatalogs", ["domains"]),
    ...mapState("helpCenter", ["questionsList"]),
    offset() {
      return this.page === 1 ? 0 : (this.page - 1) * 30;
    },
    titleLanguages() {
      return this.domains.filter(
        domain => domain.language === "ru" || domain.language === "en"
      );
    }
  },
  methods: {
    ...mapActions("helpCenter", ["getQuestionsList", "deleteQuestion"]),
    ...mapActions("cmsApiCatalogs", ["getDomains"]),
    getQuestionsListEvent() {
      this.$store.dispatch("setLoader", true);
      return this.getQuestionsList({ offset: this.offset }).finally(() =>
        this.$store.dispatch("setLoader", false)
      );
    },
    nextPage(page) {
      this.page = page;
      this.getQuestionsListEvent();
    },

    handleEdit(id) {
      this.$router.push({ name: "edit", params: { id } });
    },

    async handleDelete(id) {
      this.$store.dispatch("setLoader", true);
      try {
        await this.deleteQuestion(id);
        await this.getQuestionsListEvent();
        this.$notify({ type: "success", title: "Question deleted" });
      } catch (e) {
        this.$notify({ type: "warning", title: "Error" });
      } finally {
        this.$store.dispatch("setLoader", false);
      }
    }
  },
  created() {
    this.getQuestionsListEvent();
    this.getDomains();
  }
};
</script>
