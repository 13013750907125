<template>
  <card title="Flags">
    <el-checkbox v-for="flag in clinic.flags"
                 :key="flag.name"
                 v-model="flag.value"
    >
      {{ flag.name }}
    </el-checkbox>
  </card>
</template>

<script>
import { mapState } from "vuex";

export default {
  data: () => ({
    defaultFlags: [
      {
        'name' : 'is_hide',
        'value' : false
      },
      {
        'name' : 'has_active_agencies',
        'value' : false
      },
    ]
  }),
  computed: {
    ...mapState('clinics', ['clinic']),
  },
  created() {
    let tmpArray = [];

    this.clinic.flags = [...this.clinic.flags, ...this.defaultFlags]
      .filter(item => {
        if (tmpArray.indexOf(item.name) === -1) {
          tmpArray.push(item.name);
          return true
        }
        return false;
      });
  }
}
</script>
