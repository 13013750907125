<template>
  <card title="Name info">
    <base-input :label="`Name ${key.toUpperCase()}`"
                v-for="(value, key) in fullItem.name"
                :key="key"
                v-model="fullItem.name[key]"
                :placeholder="`Name ${key.toUpperCase()}`">
    </base-input>
    <base-input placeholder="Alias"
                v-if="fullItem.alias && fullItem.alias.en"
                :value="fullItem.alias.en"
                label="Alias"
                disabled></base-input>
    <a :href="`https://en.bookimed.com/${type}/${fullItem.alias.en}/`"
       target="_blank"
       type="button"
       class="btn btn-primary mt-16">Show on site</a>
  </card>
</template>

<script>
import { mapActions, mapState } from "vuex";

  export default {
    props: ['fullItem', 'type'],
    computed: {
      ...mapState('cmsApiCatalogs', ['domains']),
    },
    created() {
      this.getDomainsEvent();
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getDomains']),
      async getDomainsEvent() {
        await this.getDomains();
        return this.buildNames();
      },
      buildNames() {
        let name = {};

        this.domains.forEach(domain => {
          name[domain.language] = this.fullItem.name[domain.language] || this.fullItem.name.en;
        });

        this.fullItem.name = name;
      },
    }
  }
</script>
