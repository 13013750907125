<template>
  <div>
    <ReviewsAnalytic />
    <ReviewFilters :page="page" @reset-reviews-page="page = 1"/>
    <ReviewsTable/>
    <el-pagination
      v-if="pageCount > 1"
      background
      prev-text="Previous"
      next-text="Next"
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      :current-page.sync="page"
      class="text-center mt-20"
      :page-count="pageCount">
    </el-pagination>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import ReviewFilters from '@/components/Reviews/ReviewFilters';
import ReviewsTable from "../components/Reviews/ReviewsTable";
import ReviewsAnalytic from "@/components/Reviews/ReviewsAnalytic";

export default {
  components: {
    ReviewsAnalytic,
    ReviewsTable,
    ReviewFilters,
  },
  data: () => ({
    page: 1,
    pageSize: 20,
  }),
  computed: {
    ...mapState('reviews', ['reviewsMeta']),
    pageCount() {
      return this.reviewsMeta['total_page'];
    }
  },
};
</script>
