<template>
  <el-tabs v-model="activeName">
    <el-tab-pane
      v-for="domain in domains"
      :key="domain.language"
      :label="tabLabel(domain.language)"
      :name="tabLabel(domain.language)"
    >
      <card :title="tabLabel(domain.language)">
        <ckeditor
          v-if="activeName === tabLabel(domain.language)"
          v-model="clinic[descriptionType][domain.language]"
          :config="ckeditorConfig">
        </ckeditor>
      </card>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    descriptionType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeName: '',
      ckeditorConfig: { allowedContent: true },
    };
  },
  computed: {
    ...mapState('cmsApiCatalogs', ['domains']),
    ...mapState('clinics', ['clinic']),
  },
  created() {
    this.activeName = this.tabLabel(this.domains[0].language);
  },
  methods: {
    tabLabel(language) {
      return `Text ${language.toUpperCase()}`;
    },
  },
};
</script>
