<template>
  <div class="row">
    <div class="col-12">
      <card title="Reviews">
        <table class="table table-responsive" v-if="reviews && reviews.length">
          <tr>
            <th>ID</th>
            <th>Created At</th>
            <th>Manager</th>
            <th>Doctor</th>
            <th># Order</th>
            <th>User</th>
            <th>Description</th>
            <th>Procedures</th>
            <th>Clinic</th>
            <th>Lang Version</th>
            <th>Rating list</th>
            <th>Is approved</th>
            <th>Edit</th>
            <th></th>
          </tr>
          <tr v-for="review in reviews" :key="review.id">
                <td>{{review.id}}</td>
                <td>{{ review.created_at.substr(0, 10) }}</td>
                <td>{{ review.order && review.order.manager ? review.order.manager.name : '(not set)'  }}</td>
                <td>{{review.doctor && review.doctor.name ? review.doctor.name.en : '(not set)'}}</td>
                <td>
                  {{review.order ? review.order.feedback_id : '(not set)'}}
                  <template v-if="review.order.feedback_id">
                    <a :href="`https://crm.bookimed.com/order?feedbackId=${review.order.order_id}`" target="_blank">
                      CRM
                    </a>
                  </template>
                </td>
                <td>{{review.user ? review.user.name : '(not set)'}}</td>
                <td>
                  <div class="max-width-300" v-if="getReviewText(review.text)">
                    <div :class="{'over-flow-text': !review.hideDescription}">{{getReviewText(review.text)}}</div>
                    <button @click="$set(review, 'hideDescription', !review.hideDescription)" v-if="getReviewText(review.text).length > 50">
                      {{review.hideDescription ? 'Hide' : 'Show all'}}
                    </button>
                  </div>
                </td>
                <td>
                  <template v-if="review.order">
                    <template v-if="getSicks(review.order.operation_ids, 'operations').length">
                    <span v-for="(operation, index) in getSicks(review.order.operation_ids, 'operations')" :key="operation.id">
                      {{operation.name ? operation.name.en : ''}}
                      <template v-if="getSicks(review.order.diagnostic_ids, 'diagnostics').length">,</template>
                    </span>
                    </template>
                    <template v-if="getSicks(review.order.diagnostic_ids, 'diagnostics').length">
                      <span v-for="(diagnostic, index) in getSicks(review.order.diagnostic_ids, 'diagnostics')" :key="diagnostic.id">
                        {{diagnostic.name ? diagnostic.name.en : ''}}<template v-if="getSicks(review.order.diagnostic_ids, 'diagnostics').length - 1 !== index">,</template>
                      </span>
                    </template>
                  </template>
                </td>
                <td>
                  <a v-if="review.clinic && review.clinic.name"
                     target="_blank"
                     :href="`https://en.bookimed.com/clinic/${review.clinic.alias.en}`">
                    {{review.clinic.name.en || review.clinic.name.ru}}
                  </a>
                </td>
                <td>{{review.base_locale}}</td>
                <td>
                  <template v-if="review.ratings && review.ratings.length">
                    <div v-for="rating in review.ratings">
                      <strong>{{rating.name}}:</strong> {{rating.value}}
                    </div>
                  </template>
                </td>
                <td>{{isApproved(review.flags)}}</td>
                <td>
                  <button class="btn btn-primary btn-link" @click="editItem(review.id)">
                    <i class="tim-icons icon-pencil"></i>
                  </button>
                </td>
          </tr>
        </table>
        <div class="card-body" v-else>
          No data
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

  export default {
    computed: {
      ...mapState('cmsApiCatalogs', ['countries', 'illnesses', 'operations', 'diagnostics']),
      ...mapState('reviews', ['reviews']),
    },
    methods: {
      getReviewText(text) {
        return text ? (text.en || text.ru) : '';
      },
      isApproved(flags) {
        return !!flags && flags.find(el => el.name === 'is_approved') && flags.find(el => el.name === 'is_approved').value ? 'Yes' : 'No';
      },
      editItem(reviewId) {
        let routeData = this.$router.resolve({ name: 'Review', params: { reviewId } });
        window.open(routeData.href, '_blank');
      },
      getSicks(sickIds, listType) {
        if (sickIds && sickIds.length) {
          return this[listType].filter(item => sickIds.includes(item.id));
        }
        return [];
      },
    }
  }
</script>
