<template>
  <Entities :entityData="entityData"/>
</template>

<script>
import Entities from '@/components/Entities/Entities';
import { mapState } from 'vuex';

export default {
  components: { Entities },
  computed: {
    ...mapState('cmsApiCatalogs', ['countriesMeta','countries']),
    entityData() {
      return {
        type: 'country',
        meta: this.countriesMeta,
        searchPlaceholder: "Country's name",
        getEntitiesQuery: 'getCountries',
        entities: this.countries,
        tableName: 'Countries',
      };
    },
  },
};
</script>

