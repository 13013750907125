<template>
  <div class="review">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Main info" name="commonInfo">
        <div class="row">
          <div class="col-md-6">
            <ReviewClinic @save-review="save()"/>
            <ReviewDoctor @save-review="save()"/>
            <ReviewSicks v-if="review.order" :order="review.order"/>
            <card title="Comment for Bookimed">
               <textarea rows="4" cols="80"
                         ref="textarea-description"
                         @input="resize()"
                         :class="{ 'textarea-open' : isAreaOpen }"
                         class="form-control"
                         placeholder="Comment for Bookimed"
                         v-model="review.comment_for_bookimed"
               ></textarea>
              <button class="btn btn-primary mt-16"
                      @click="isAreaOpen = !isAreaOpen">
                {{ isAreaOpen ? 'Hide' : 'Show more' }}
              </button>
            </card>
            <card title="CRM link" v-if="review.order">
              <a :href="`https://crm.bookimed.com/order?feedbackId=${review.order.order_id}`" target="_blank">
                {{ review.order.feedback_id }}
              </a>
            </card>
            <ReviewFlags v-if="review && review.flags"/>
          </div>
          <div class="col-md-6">
            <ReviewRating v-if="review && review.ratings"/>
          </div>
        </div>
      </el-tab-pane>
      <el-tab-pane label="Text" name="Text">
        <ReviewText v-if="domains && Object.keys(domains).length"/>
      </el-tab-pane>
      <el-tab-pane label="Client response" name="Client response" v-if="review.client_response">
        <ReviewClientResponse v-if="domains && Object.keys(domains).length"/>
      </el-tab-pane>
      <el-tab-pane label="Images" name="Images" v-if="review.images && review.images.length">
        <ReviewImages :images="review.images"/>
      </el-tab-pane>
      <div class="row" v-if="activeName !== 'Images'">
        <div class="col-12">
          <base-button type="primary" @click="save()">Update review</base-button>
        </div>
      </div>
    </el-tabs>
  </div>
</template>

<script>
import ReviewClinic from '@/components/Reviews/ReviewClinic.vue';
import ReviewDoctor from '@/components/Reviews/ReviewDoctor.vue';
import ReviewSicks from '@/components/Reviews/ReviewSicks.vue';
import ReviewFlags from '@/components/Reviews/ReviewFlags.vue';
import ReviewRating from '@/components/Reviews/ReviewRating.vue';
import ReviewText from '@/components/Reviews/ReviewText.vue';
import ReviewClientResponse from '@/components/Reviews/ReviewClientResponse';
import ReviewImages from '@/components/Reviews/ReviewImages.vue';
import { mapState, mapActions, mapMutations } from "vuex";

export default {
  props: ['reviewId'],
  components: {
    ReviewClinic,
    ReviewDoctor,
    ReviewSicks,
    ReviewFlags,
    ReviewRating,
    ReviewText,
    ReviewClientResponse,
    ReviewImages,
  },
  data: () => ({
    activeName: 'commonInfo',
    isApprovedFlagInit: false,
    isAreaOpen: false,
  }),
  computed: {
    ...mapState('reviews', ['review']),
    ...mapState('cmsApiCatalogs', ['domains']),
    isApprovedFlag() {
      if (this.review.flags && this.review.flags.length && this.review.flags.find(el => el.name === 'is_approved')) {
        return this.review.flags.find(el => el.name === 'is_approved').value;
      }
      return false;
    }
  },
  created() {
    this.getDomains();
    this.getReviewEvent();
  },
  beforeDestroy() {
    this.SET_DATA({ type: 'review', data: { text: {}} });
  },
  methods: {
    ...mapMutations('reviews', ['SET_DATA']),
    ...mapActions('reviews', ['getReview', 'updateReview', 'setReviewApprove']),
    ...mapActions('cmsApiCatalogs', ['getDomains']),
    getReviewEvent() {
      this.$store.dispatch('setLoader', true);
      return this.getReview(this.reviewId).then(() => {
        this.isApprovedFlagInit = this.isApprovedFlag;
      }).finally(() => this.$store.dispatch('setLoader', false));
    },
    save() {
      this.$store.dispatch('setLoader', true);
      return this.updateReview({
        data: { ...this.review, ratings: this.review.ratings.map(el => ({ ...el, value: +el.value }) ) },
        reviewId: this.reviewId
        }).then(() => {
          this.$notify({ type: 'success', title: 'Saved' });
        if (!this.isApprovedFlagInit && this.isApprovedFlag) {
            this.setReviewApprove(this.reviewId);
            this.isApprovedFlagInit = true;
          }
        }).catch(() => this.$notify({ type: 'warning', title: 'Save Failed' }))
        .finally(() => this.$store.dispatch('setLoader', false));
    },
    resize() {
      let element = this.$refs["textarea-description"];

      element.style.height = element.scrollHeight + "px";
    }
  }
};
</script>

<style lang="scss">
.review {
  .textarea-open {
    max-height: 100%;
  }
}
</style>

