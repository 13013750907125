<template>
  <card title="Description">
    <el-tabs v-model="activeLanguage">
      <el-tab-pane v-for="domain in domains"
                   :key="domain.language"
                   :label="domain.language.toUpperCase()"
                   :name="domain.language">
        <el-input
          v-model="clinicsMediaItem.description[domain.language]"
          :autosize="{ minRows: 6 }"
          type="textarea"
          placeholder="Description"
        />
      </el-tab-pane>
    </el-tabs>
  </card>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    activeLanguage: null,
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['clinicsMediaItem', 'domains']),
  },
  mounted() {
    this.activeLanguage = this.domains[0].language;
  },
};
</script>
