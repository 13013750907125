<template>
  <Entities :entityData="entityData"/>
</template>

<script>
import Entities from '@/components/Entities/Entities';
import { mapState } from 'vuex';

export default {
  components: { Entities },
  computed: {
    ...mapState('cmsApiCatalogs', ['diagnosticsMeta', 'diagnostics']),
    entityData() {
      return {
        type: 'diagnostic',
        meta: this.diagnosticsMeta,
        searchPlaceholder: "Diagnostic's name",
        getEntitiesQuery: 'getDiagnostics',
        entities: this.diagnostics,
        tableName: 'Diagnostics',
      };
    },
  },
};
</script>
