<template>
  <div>
    <DoctorFilters :page="page" @reset-doctor-page="page = 1"/>
    <DoctorsTable/>
    <el-pagination
      v-if="pageCount > 1"
      background
      prev-text="Previous"
      next-text="Next"
      :page-size="pageSize"
      :pager-count="5"
      layout="prev, pager, next"
      :current-page.sync="page"
      class="text-center mt-20"
      :page-count="pageCount">
    </el-pagination>
  </div>
</template>
<script>
  import DoctorFilters from '@/components/Doctors/DoctorFilters';
  import DoctorsTable from '@/components/Doctors/DoctorsTable';
  import { mapState } from 'vuex';

  export default {
    components: {
      DoctorFilters,
      DoctorsTable,
    },
    data: () => ({
      page: 1,
      pageSize: 20,
    }),
    computed: {
      ...mapState('doctors', ['doctorsMeta']),
      pageCount() {
        return this.doctorsMeta['total_page'];
      }
    },
  };
</script>
