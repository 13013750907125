<template>
  <Entities :entityData="entityData"/>
</template>

<script>
import Entities from '@/components/Entities/Entities';
import { mapState } from 'vuex';

export default {
  components: { Entities },
  computed: {
    ...mapState('cmsApiCatalogs', ['illness-kinds-meta', 'illness-kinds']),
    entityData() {
      return {
        type: 'illness-kind',
        meta: this['illness-kinds-meta'],
        searchPlaceholder: "Specialty's name",
        getEntitiesQuery: 'getIllnessKinds',
        entities: this['illness-kinds'],
        tableName: 'Specialties',
      };
    },
  },
};
</script>
