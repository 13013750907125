<template>
  <el-tabs v-model="activeName">
    <el-tab-pane v-for="domain in domains"
                 :key="domain.language"
                 :label="`Text ${domain.language.toUpperCase()}`"
                 :name="`Text ${domain.language.toUpperCase()}`">
      <card :title="`Text ${domain.language.toUpperCase()}`">
        <el-input
          v-model="review.text[domain.language]"
          :autosize="{ minRows: 4  }"
          type="textarea"
          placeholder="text"
        />
      </card>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { mapState } from 'vuex';

export default {
  data: () => ({
    activeName: null,
  }),
  computed: {
    ...mapState('cmsApiCatalogs', ['domains']),
    ...mapState('reviews', ['review']),
  },
  created() {
    this.activeName = `Text ${this.domains[0].language.toUpperCase()}`;
  },
}
</script>
