<template>
  <Entities :entityData="entityData"/>
</template>

<script>
import Entities from '@/components/Entities/Entities';
import { mapState } from 'vuex';

export default {
  components: { Entities },
  computed: {
    ...mapState('cmsApiCatalogs', ['citiesMeta', 'cities']),
    entityData() {
      return {
        type: 'city',
        meta: this.citiesMeta,
        searchPlaceholder: "City's name",
        getEntitiesQuery: 'getCities',
        entities: this.cities,
        tableName: 'Cities',
      };
    },
  },
};
</script>
