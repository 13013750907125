<template>
  <div class="row">
    <div class="col-12">
      <card title="Clinics">
        <table class="table table-responsive" v-if="clinics && clinics.length">
          <tr>
            <th>ID</th>
            <th></th>
            <th>Name</th>
            <th>Description</th>
            <th>Illnesses</th>
            <th>Operations</th>
            <th>Rating</th>
            <th>Is hide</th>
            <th></th>
          </tr>
          <tr v-for="clinic in clinics" :key="clinic.id">
            <td>{{clinic.id}}</td>
            <td>
              <img :src="showClinicImg(clinic.images)"
                   class="clinics__ava">
            </td>
            <td>
              <a v-if="clinic && clinic.name"
                 target="_blank"
                 :href="`https://en.bookimed.com/clinic/${clinic.alias.en}`">
                {{clinic.name.en || clinic.name.ru}}
              </a>

              <template v-if="cities && cities.length && getCityAndCountry(clinic)">
                <div>{{getCityAndCountry(clinic).cityName}}, {{getCityAndCountry(clinic).countryName}}</div>
              </template>
            </td>
            <td>
              <div class="max-width-300" v-if="clinic.short_description">
                <div v-html="clinic.short_description.en" :class="{'over-flow-text': !clinic.hideDescription}"></div>
                <button @click="$set(clinic, 'hideDescription', !clinic.hideDescription)" v-if="clinic.short_description.en.length > 50">
                  {{clinic.hideDescription ? 'Hide' : 'Show all'}}
                </button>
              </div>
            </td>
            <td>
              <div v-if="getFullDoctorSicks(clinic.illness_ids, 'illnesses').length" class="max-width-300">
                <div :class="{'over-flow-text': !clinic.hideIllnesses}">
                    <span v-for="(clinicIllness, index) in getFullDoctorSicks(clinic.illness_ids, 'illnesses')"
                          :key="clinicIllness.id">
                    {{clinicIllness.name ? clinicIllness.name.en : ''}}<template v-if="getFullDoctorSicks(clinic.illness_ids, 'illnesses').length - 1 !== index">,</template>
                  </span>
                </div>
                <button @click="$set(clinic, 'hideIllnesses', !clinic.hideIllnesses)" v-if="getFullDoctorSicks(clinic.illness_ids, 'illnesses').length > 2">
                  {{clinic.hideIllnesses ? 'Hide' : 'Show all'}}
                </button>
              </div>
            </td>
            <td>
              <div v-if="getFullDoctorSicks(clinic.operation_ids, 'operations').length" class="max-width-300">
                <div :class="{'over-flow-text': !clinic.hideOperations}">
                     <span v-for="(clinicOperation, index) in getFullDoctorSicks(clinic.operation_ids, 'operations')" :key="clinicOperation.id">
                    {{clinicOperation.name ? clinicOperation.name.en : ''}}<template v-if="getFullDoctorSicks(clinic.operation_ids, 'operations').length - 1 !== index">,</template>
                  </span>
                </div>
                <button @click="$set(clinic, 'hideOperations', !clinic.hideOperations)" v-if="getFullDoctorSicks(clinic.operation_ids, 'operations').length > 2">
                  {{clinic.hideOperations ? 'Hide' : 'Show all'}}
                </button>
              </div>
            </td>
            <td>{{clinic.rating.rating}}</td>
            <td>
              <template v-if="clinic.flags && clinic.flags.length">
                {{isClinicHide(clinic.flags)}}
              </template>
            </td>
            <td>
              <button class="btn btn-primary btn-link"
                      @click="editItem(clinic.id)">
                <i class="tim-icons icon-pencil"></i>
              </button>
            </td>
          </tr>
        </table>
        <div class="card-body" v-else>
          No data
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

  export default {
    computed: {
      ...mapState('cmsApiCatalogs', ['cities', 'illnesses', 'operations']),
      ...mapState('clinics', ['clinics']),
    },
    created() {
      this.getIllnesses({ perPage: 1500 });
      this.getOperations({ perPage: 1500 });
    },
    methods: {
      ...mapActions('cmsApiCatalogs', ['getIllnesses', 'getOperations']),
      editItem(clinicId) {
        let routeData = this.$router.resolve({ name: 'Clinic', params: { clinicId } });
        window.open(routeData.href, '_blank');
      },
      getFullDoctorSicks(sickIds, listType) {
        if (sickIds && sickIds.length) {
          return this[listType].filter(item => sickIds.includes(item.id));
        }
        return [];
      },
      isClinicHide(flags) {
        const findIsHideFlag = flags.find(flag => flag.name === 'is_hide');

        return findIsHideFlag.value ? 'Yes' : 'No';
      },
      showClinicImg(clinicImages) {
        if (clinicImages && clinicImages.length) {
          const clinicImgUrl = clinicImages[0].base_url;
          const clinicImgHash = clinicImgUrl.slice(clinicImgUrl.lastIndexOf('/') + 1)
          return `https://img.bookimed.com/clinic/${clinicImgHash}_b.jpeg`;
        }
      },
      getCityAndCountry(clinic) {
        if (clinic.geo) {
          const cityCountryInfo = this.cities.find(city => city.id === clinic.geo.city_id);
          if (cityCountryInfo && cityCountryInfo.name) {
            return {
              cityName: cityCountryInfo.name.en,
              countryName: cityCountryInfo.country.name.en,
            }
          }
        }
        return null;
      },
    },
  }
</script>

<style lang="scss">
.clinics{
  &__sicks{
    max-width: 300px;
  }
  &__ava{
    min-width: 70px;
    width: 70px;
  }
}
</style>
