<template>
  <card title="Doctor">
    <a v-if="review.doctor && review.doctor.alias"
       class="fs-18"
       :href="`https://en.bookimed.com/doctor/${review.doctor.alias.en}/`"
       target="_blank">{{ review.doctor.name ? review.doctor.name.en : '-' }}</a>
    <div class="row mt-16">
      <div class="col-12">
        <base-input label="Select doctor ID"
                    v-model="doctorId"
                    placeholder="Select doctor ID">
        </base-input>
        <div v-if="doctorById">
          {{doctorById.name.en || doctorById.name.ru}}
        </div>
      </div>
    </div>
    <div class="row mt-16">
      <div class="col-12">
        <base-button type="primary"
                     :disabled="!doctorId"
                     class="mr-16"
                     @click="findDoctor()">Find doctor</base-button>
        <base-button type="primary"
                     v-if="doctorById"
                     @click="updateDoctor()">Update doctor</base-button>
      </div>
    </div>
  </card>
</template>

<script>
  import { mapState } from "vuex";
  import APIDoctors from '@/api/modules/doctors';

  export default {
    data: () => ({
      doctorId: '',
      doctorById: null,
    }),
    computed: {
      ...mapState('reviews', ['review']),
    },
    methods: {
      findDoctor() {
        this.$store.dispatch('setLoader', true);
        this.doctorById = null;
        return APIDoctors.getDoctor(this.doctorId).then((response) => {
          if (response && response.data) {
            if (response.data.id) {
              let { id, alias, name } = response.data;

              this.doctorById = { id, alias, name };
            } else {
              if (response.data.error && response.data.error.data) {
                alert(response.data.error.data.message);
              }
            }
          } else {
            alert('Something wrong');
          }
        }).finally(() =>  this.$store.dispatch('setLoader', false));
      },
      updateDoctor() {
        this.review.doctor = this.doctorById;
        this.$emit('save-review');
      }
    },
  }
</script>
