<template>
  <Entity :entityData="entityData"/>
</template>

<script>
import Entity from '@/components/Entities/Entity';
import { mapState } from 'vuex';

export default {
  props: ['entityId'],
  components: { Entity },
  computed: {
    ...mapState('cmsApiCatalogs', ['illness']),
    entityData() {
      return {
        type: 'illness',
        entitiesString: 'illnesses',
        entity: this.illness.illness,
        details: this.illness.details,
        entityId: this.entityId,
        texts: {
          getMethod: 'getEntityTexts',
          saveMethod: 'saveEntityTexts',
          entitiesString: 'illnesses',
          defaultTexts: [
            { text: '', alias: 'guide_mc'},
            { text: '', alias: 'txt_short'},
            { text: '', alias: 'txt_long'},
            { text: '', alias: 'txt_illness'},
            { text: '', alias: 'txt_illness_3_clinics'},
            { text: '', alias: 'trust_block'},
            { text: '', alias: 'medical_analysis'},
            { text: '', alias: 'txt_price'}
          ],
        },
      };
    },
  },
}
</script>
