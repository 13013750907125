<template>
  <div class="row">
    <div class="col-12">
      <card title="Analytics">
        <div class="analytics">
          <div class="row">
            <el-date-picker v-model="date"
                            type="daterange"
                            data-type="start date"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            popper-class="analytics__date-picker"
                            @change="getAnalytics()"
            />
          </div>
          <div v-if="analytic && analytic.length">
            <div class="analytics__content" :class="{ 'active' : !showAllStatistic }">
              <div class="column">
                <div class="analytics__main-title">Reviews</div>
                <div class="analytics__wrapper">
                  <div class="analytics__title">All</div>
                  <div class="analytics__list">
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Total</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.total }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Empty</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.empty }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Only mark</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.only_mark }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">With text</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.with_text }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">With photo</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.with_photo }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="analytics__wrapper">
                  <div class="analytics__title">Status arrived</div>
                  <div class="analytics__list">
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Total</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.total_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Empty</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.empty_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Only mark</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.only_mark_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">With text</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.with_text_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">With photo</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.with_photo_status_arrived }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="analytics__wrapper">
                  <div class="analytics__title">Remote consultation</div>
                  <div class="analytics__list">
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Total</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.total_remote_consultation }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Empty</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.empty_remote_consultation }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Only mark</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.only_mark_remote_consultation }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">With text</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.with_text_remote_consultation }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">With photo</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.with_photo_remote_consultation }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="analytics__main-title">Emails</div>
                <div class="analytics__wrapper">
                  <div class="analytics__title">All</div>
                  <div class="analytics__list">
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send mails</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_mails_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send second mails</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_second_mails_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send messages</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_messages_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send second messages</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_second_messages_after_status_arrived }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="analytics__wrapper">
                  <div class="analytics__title">Status Arrived</div>
                  <div class="analytics__list">
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send mails</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_mails_after_status_arrived  - analytic[0].data.remote_consultation_send_mails_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send second mails</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_second_mails_after_status_arrived - analytic[0].data.remote_consultation_send_second_mails_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send messages</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_messages_after_status_arrived - analytic[0].data.remote_consultation_send_messages_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send second messages</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.send_second_messages_after_status_arrived - analytic[0].data.remote_consultation_send_second_messages_after_status_arrived }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="analytics__wrapper">
                  <div class="analytics__title">Remote consultation</div>
                  <div class="analytics__list">
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send mails</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.remote_consultation_send_mails_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send second mails</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.remote_consultation_send_second_mails_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send messages</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.remote_consultation_send_messages_after_status_arrived }}
                      </div>
                    </div>
                    <div class="analytics__list-item">
                      <div class="analytics__list-item-name">Send second messages</div>
                      <div class="analytics__list-item-value">
                        {{ analytic[0].data.remote_consultation_send_second_messages_after_status_arrived }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="analytics__button">
              <button class="btn btn-default" @click="showAllStatistic = !showAllStatistic">
                {{ showAllStatistic ? 'More details' : 'Hide details' }}
              </button>
            </div>
          </div>
          <div class="card-body" v-else>
            No data
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

export default {
  data: () => ({
    date: [],
    showAllStatistic: false,
  }),
  computed: {
    ...mapState('reviews', ['analytic']),
  },
  methods: {
    ...mapActions('reviews', ['getReviewsAnalytics']),
    getAnalytics() {
      this.getReviewsAnalytics({date_from: this.date[0], strategy: 'aggregated_from_date_to_date', date_to: this.date[1]});
    },
  },
};
</script>

<style lang="scss">
.analytics {
  display: flex;
  flex-direction: column;
  gap: 27px;
  padding: 0 15px;

  &__content {
    display: flex;
    overflow: hidden;
    height: 270px;
    margin-bottom: 25px;
    gap: 20px;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }

  &__button {
    display: flex;
    justify-content: center;
  }

  &__main-title {
    font-weight: 600;
    font-size: 18px;
    padding-bottom: 10px;
    color: #222d32;
  }

  &__wrapper {
    border-radius: 12px;
    padding: 16px;
    margin: 10px 0;
    color: #222d32;
    background: #FAFAFA;
    min-height: 215px;
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    padding-bottom: 20px;
  }

  &__list {
    border: 1px solid #222d32;
    border-radius: 4px;
    font-weight: 600;
    font-size: 0.7rem;
    text-transform: uppercase;

    &-item {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #222d32;
      &:last-of-type {
        border-bottom: 0;
      }

      &-name {
        padding: 5px 0 0 5px;
      }

      &-value {
        border-left: 1px solid #222d32;
        padding: 5px 10px;
      }
    }
  }

  .column {
    display: flex;
    flex-direction: column;
    width: 50%;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }
  .active {
    height: auto;
  }

  .el-range-editor.el-input__inner {
    border: 1px solid #222d32;
  }
  .el-date-editor .el-range__icon {
    color: #222d32;
  }
  .el-date-editor .el-range__close-icon {
    color: #222d32;
  }
  .el-date-editor .el-range-input {
    font-weight: 600;
    font-size: 0.8rem;
    color: #222d32;
  }
}
</style>
