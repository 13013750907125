<template>
  <card title="Clinic" v-if="review.clinic">
    <a class="fs-18"
       target="_blank"
       :href="`https://en.bookimed.com/clinic/${review.clinic.alias.en}`">
      {{review.clinic.name.en || review.clinic.name.ru}}
    </a>
    <div class="row mt-16">
      <div class="col-12">
        <base-input label="Select clinic ID"
                    v-model="clinicId"
                    placeholder="Select clinic ID">
        </base-input>
        <div v-if="clinicById">
          {{clinicById.name.en || clinicById.name.ru}}
        </div>
      </div>
    </div>
    <div class="row mt-16">
      <div class="col-12">
        <base-button type="primary"
                     :disabled="!clinicId"
                     class="mr-16"
                     @click="findClinic()">Find clinic</base-button>
        <base-button type="primary"
                     v-if="clinicById"
                     @click="updateClinic()">Update clinic</base-button>
      </div>
    </div>
  </card>
</template>

<script>
  import { mapState } from "vuex";
  import APIClinics from '@/api/modules/clinics';

  export default {
    data: () => ({
      clinicId: '',
      clinicById: null,
    }),
    computed: {
      ...mapState('reviews', ['review']),
    },
    methods: {
      findClinic() {
        this.$store.dispatch('setLoader', true);
        this.clinicById = null;
        return APIClinics.getClinic(this.clinicId).then((response) => {
          if (response && response.data) {
            if (response.data.clinic) {
              let { id, alias, name, geo } = response.data.clinic;

              this.clinicById = { id, alias, name, country_code: geo.country_code };
            } else {
              if (response.data.error && response.data.error.data) {
                alert(response.data.error.data.message);
              }
            }
          } else {
            alert('Something wrong');
          }
        }).finally(() =>  this.$store.dispatch('setLoader', false));
      },
      updateClinic() {
        this.review.clinic = this.clinicById;
        this.$emit('save-review');
      }
    },
  }
</script>
