<template>
  <card title="Name info">
    <template v-if="$route.name === 'country'">
      <el-select v-model="entity.country_group"
                 placeholder="Country group"
                 no-data-text="No data"
                 no-match-text="No match"
                 filterable
                 reserve-keyword
                 value-key="id">
        <el-option v-for="item in сountriesGroup"
                   :key="item.id"
                   :label="item.name.en"
                   :value="item">
        </el-option>
      </el-select>
      <base-input placeholder="ISO_code"
                  :value="entity.code"
                  :disabled="$route.params.entityId !== 'new'"
                  label="ISO Code"
                  v-model="entity.code"></base-input>
    </template>
    <base-input :label="`Name ${key.toUpperCase()}`"
                v-for="(value, key) in entity.name"
                :key="key"
                v-model="entity.name[key]"
                :placeholder="`Name ${key.toUpperCase()}`">
    </base-input>
    <base-input placeholder="Alias"
                v-if="entity.alias && entity.alias.en"
                :value="entity.alias.en"
                label="Alias"
                disabled></base-input>
    <div class="entities-links" v-if="Object.keys(entity.alias).length && $route.name !== 'city' && $route.name !== 'country'">
      <h3>Show on site:</h3>
        <a v-for="(value, key) in entity.name" :key="key"
           v-if="entity.alias[key]"
           :href="`https://${key}.bookimed.com/clinics/${findEntityName}=${entity.alias[key]}/`"
           target="_blank"
           type="button"
           class="btn btn-primary entities-btn">{{key.toUpperCase()}}</a>
    </div>
  </card>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  props: ['entity'],
  computed: {
    ...mapState('cmsApiCatalogs', ['domains']),
    ...mapState('data', ['сountriesGroup']),
    findEntityName() {
      switch (this.$route.name) {
        case 'illness': return 'illness';
        case 'illness-kind': return 'direction';
        default: return 'procedure';
      }
    },
  },
  created() {
    this.buildNames();

    if (this.$route.name === 'country') {
      this.getCountriesGroup();
    }
  },
  watch: {
    entity() {
      this.buildNames();
    }
  },
  methods: {
    ...mapActions('data', ['getCountriesGroup']),
    buildNames() {
      let name = {};

      this.domains.forEach(domain => {
        name[domain.language] = this.entity.name[domain.language] || '';
      });

      this.entity.name = name;
    },
  }
}
</script>

<style lang="scss">
.entities-links {
  display: flex;
  flex-wrap: wrap;
  gap: 7px;
  align-items: center;
  h3 {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
  }
  .entities-btn {
    padding: 5px 15px;
  }
}
</style>
