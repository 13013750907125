<template>
  <card title="Filters">
    <div class="row">
      <div class="col-md-3 pr-md-1">
        <base-input placeholder="Clinic's name"
                    v-model="filters.clinic_name">
        </base-input>
      </div>
      <div class="col-md-3 px-md-1">
        <el-select v-model="filters.country_codes"
                   placeholder="Countries"
                   no-data-text="No data"
                   no-match-text="No match"
                   filterable
                   reserve-keyword
                   multiple
                   value-key="id">
          <el-option v-for="country in countries"
                     :key="country.code"
                     :label="country.name.en"
                     :value="country.code">
          </el-option>
        </el-select>
      </div>
      <div class="col-md-2 pr-md-1">
        <el-checkbox v-model="filters.only_hide">Only hide</el-checkbox>
      </div>
      <div class="col-md-2 px-md-1">
        <base-button @click="clearFilters()">Clear filters</base-button>
      </div>
    </div>
  </card>
</template>

<script>
import { mapState, mapActions } from 'vuex';

  export default {
    props: ['page'],
    data: () => ({
      filters: {
        only_hide: false,
        clinic_name: '',
        country_codes: [],
      },
    }),
    computed: {
      ...mapState('cmsApiCatalogs', ['countries']),
      ...mapState('clinics', ['clinics']),
    },
    watch: {
      filters: {
        immediate: true,
        deep: true,
        handler: 'setFilter',
      },
      page() {
        this.setFilter(this.page);
      },
    },
    created() {
      this.getCountries({ perPage: 1500 });
    },
    methods: {
      ...mapActions('clinics', ['getClinics']),
      ...mapActions('cmsApiCatalogs', ['getCountries', 'getCities']),
      getClinicsEvent(params) {
        this.$store.dispatch('setLoader', true);
        return this.getClinics(params).finally(() => this.$store.dispatch('setLoader', false));
      },
      buildFilters() {
        return {
          only_hide: false,
          clinic_name: '',
          country_codes: [],
        };
      },
      setFilter(currentPage) {
        let page = null;

        if (!isNaN(currentPage)) {
          page = currentPage;
        }

        const purifiedFilters = this.cleanFilters(this.filters);
        const params = { ...purifiedFilters };
        if (page) {
          params.page = page;
        } else {
          this.$emit('reset-clinics-page');
        }
        return this.getClinicsEvent(params).then(() => {
          this.getCities({ perPage: 1500, ids: [...new Set(this.clinics.map(clinic => clinic.geo.city_id))] });
        });
      },
      cleanFilters(filters) {
        return Object.keys(filters).reduce((acc, key) => {
          const value = filters[key];
          const isArray = Array.isArray(value);

          if (value || (isArray && value.length >= 1)) {
            acc[key] = value;
          }

          return acc;
        }, {});
      },
      clearFilters() {
        this.filters = this.buildFilters();
        this.$emit('reset-clinics-page');
      },
    }
  }
</script>
