<template>
  <card title="Clinic's images">
    <div class="photos__list">
      <div class="photos__list-main photos__list-item">
        <img class="photos__list-photo"
             v-if="clinic.images[0]"
             :src="showClinicImg(clinic.images[0].base_url)">
        <div class="photos__list-no-data" v-else>
          <img class="select-photo" src="@/assets/imgs/select-photo.svg">
          <div class="photos__list-no-data-title">No data</div>
        </div>
        <span class="photos__list-item-index">1</span>
      </div>
      <div class="photos__list-extra">
        <div class="photos__list-extra-item photos__list-item"
             v-for="index in 4"
             v-show="index >= 1 && index <= 4"
             :key="index">
          <img class="photos__list-photo"
               v-if="clinic.images[index]"
               :src="showClinicImg(clinic.images[index].base_url)">
          <div class="photos__list-no-data" v-else>
            <img class="select-photo" src="@/assets/imgs/select-photo.svg">
            <div class="photos__list-no-data-title">No data</div>
          </div>
          <span class="photos__list-item-index">{{index + 1}}</span>
        </div>
      </div>
    </div>
    <div class="photos__list-draggable">
      <div class="photos__list-draggable-item photos__list-draggable-item-top">
        <div class="photos__list-draggable-descr">
          These 5 photos are on the first screen on the clinic's page. Drag and drop the needed photo to change it.
        </div>
        <div class="photos__list-draggable-list-wrap">
          <draggable class="photos__list-draggable-list"
                     :list="clinic.images"
                     group="photos"
          >
            <div
              class="photos__list-item"
              :class="{'photos__list-item-has-photo': showClinicImg(image.base_url)}"
              v-for="(image, index) in clinic.images"
              :key="index"
            >
              <img :src="showClinicImg(image.base_url)">
              <span class="photos__list-item-index" v-if="index < 5">{{index + 1}}</span>
              <i @click="clinic.images.splice(index, 1)" class="tim-icons icon-simple-remove"></i>
            </div>
            <div class="photos__adding" @click="$refs.file.click()">+</div>
          </draggable>
        </div>
        <input type="file" id="file" ref="file" @change="handleFileUpload" style="position: absolute;left: -5000px;"/>
      </div>
    </div>
  </card>
</template>

<script>
import APIClinics from '@/api/modules/clinics';
import { mapState } from 'vuex';
import draggable from "vuedraggable";

export default {
  components: {
    draggable
  },
  computed: {
    ...mapState('clinics', ['clinic']),
  },
  methods: {
    handleFileUpload() {
      let file = this.$refs.file.files[0];

      if (file.type && (file.type === 'image/jpg' || file.type === 'image/jpeg')) {
        this.$store.dispatch('setLoader', true);
        return APIClinics.addImages({ file, clinicId: this.clinic.id })
          .then(() => this.$emit('get-clinic-event'))
          .finally(() => this.$store.dispatch('setLoader', false));
      } else {
        alert('Make sure your photos have .jpg, .jpeg, file types');
      }
    },
    showClinicImg(baseUrl) {
      const clinicImgHash = baseUrl.slice(baseUrl.lastIndexOf('/') + 1);
      return `https://img.bookimed.com/clinic/${clinicImgHash}_b.jpeg`;
    },
  }
}
</script>


<style lang="scss">
.photos{
  &__adding{
    background: #E7E7E7;
    opacity: 0.5;
    border: 3px solid #A3CC0E;
    border-radius: 3px;
    width: 78px;
    height: 78px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 32px;
    cursor: pointer;
    margin-top: 10px;
    color: #A3CC0E;
  }
  &__wrap{
    position: relative;
    margin-right: -24px;
  }
  &__list{
    display: flex;
    height: 510px;
    border: 1px solid #DFE1E5;
    &-draggable{
      margin-top: 13px;
      &-item{
        margin-bottom: 15px;
        &-top{
          .photos__list-draggable-list{
            border: 1px solid #ccc;
            padding: 0 10px 10px;
          }
        }
        .photos__list-item-has-photo{
          &:hover{
            i{
              display: block;
            }
            &:after{
              content: '';
              z-index: 1;
              position: absolute;
              width: 100%;
              height: 100%;
              background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4));
              top: 0;
              left: 0;
            }
          }
        }
      }
      &-descr{
        font-size: 13px;
        line-height: 140%;
        color: rgba(0, 0, 0, 0.38);
        margin-bottom: 7px;
        font-weight: normal;
      }
      &-list{
        display: flex;
        flex-wrap: wrap;
        min-height: 88px;
        &-wrap{
          display: flex;
        }
      }
      .photos__list-item{
        width: 78px;
        height: 78px;
        margin-right: 10px;
        border: 1px solid #DFE1E5;
        border-radius: 3px;
        margin-top: 10px;
        i{
          display: none;
          color: #F2F2F2;
          font-size: 18px;
          position: absolute;
          right: 5px;
          top: 5px;
          z-index: 2;
          cursor: pointer;
        }
        .photos__list-item-index{
          top: 5px;
          left: 5px;
        }
        img{
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
      }
    }
    &-dialog{
      height: 300px;
    }
    &-main, &-extra{
      position: relative;
      width: 50%;
      height: 100%;
    }
    &-main{
      border-right: 2px solid #DFE1E5;
    }
    &-extra{
      display: flex;
      flex-wrap: wrap;
      &-item{
        height: 50%;
        width: 50%;
        &:nth-of-type(1) {
          border-bottom: 2px solid #DFE1E5;
          border-right: 2px solid #DFE1E5;
        }
        &:nth-of-type(2) {
          border-bottom: 2px solid #DFE1E5;
        }
        &:nth-of-type(3) {
          border-right: 2px solid #DFE1E5;
        }
      }
    }
    &-item{
      position: relative;
      &-index{
        position: absolute;
        left: 10px;
        top: 10px;
        width: 18px;
        height: 18px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 50%;
        color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 10;
        font-size: 13px;
      }
    }
    &-photo{
      width: 100%;
      height: 100% !important;
      object-fit: cover;
      position: relative;
      z-index: 1;
    }
    &-no-data{
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      height: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      align-content: center;
      &-title{
        letter-spacing: 0.15px;
        font-weight: bold;
        font-size: 19px;
        color: rgba(0, 0, 0, 0.56);
        margin-top: 15px;
        width: 100%;
      }
    }
  }
  &__edit-icon{
    z-index: 3;
    background: rgba(255, 255, 255, 0.8);
    width: 77px;
    height: 77px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 20px;
    bottom: 20px;
    border-radius: 50%;
    cursor: pointer;
  }
}
</style>
