<template>
  <card title="Flags">
    <el-checkbox v-model="entityData.entity.is_hide">Is hide</el-checkbox>
    <el-checkbox v-model="entityData.entity.use_for_listing"
                 v-if="isProcedure">Use for listing</el-checkbox>
  </card>
</template>

<script>
export default {
  props: ['entityData'],
  computed: {
    isProcedure() {
      return this.entityData.type === 'diagnostic' || this.entityData.type === 'operation';
    },
  }
}
</script>
